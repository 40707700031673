import { ref } from 'vue';
import { domToPng } from 'modern-screenshot';

const loadingImage = ref(false);
const openShareModal = ref(false);
const imageDataUrl = ref('');

const imageDimensions = ref(null);

const uploadedImageUrl = ref(null);

const style = {
  bg_warm: {
    background: 'linear-gradient(137.36deg, #FF3D00 -59.82%, #1A0917 76.03%)',
  },
  bg_lukewarm: {
    background:
      'linear-gradient(192.25deg, rgb(255, 184, 0) -64.04%, rgb(190, 134, 2) -19.31%, #1C0C06 91.08%)',
  },
  bg_chilly: {
    background:
      'linear-gradient(180deg, #028C8C -28.51%, rgb(7, 36, 43) 50.87%)',
  },
  bg_frozen: {
    background: 'linear-gradient(180deg, #394988 -27.16%, #020E24 100%)',
  },
  bg_subzero: {
    background: 'linear-gradient(359.05deg, #000000 0.77%, #09002C 80.84%)',
  },
};

const gradiantClasses = [
  'warm-text-gradient',
  'lukewarm-text-gradient',
  'chilly-text-gradient',
  'frozen-text-gradient',
  'subzero-text-gradient',
];

const makePng = async (
  id,
  background_class = null,
  modalOpener = null,
  dimensions = null,
  shareElemId = null,
) => {
  if (dimensions) imageDimensions.value = dimensions;
  overrideXML();
  try {
    loadingImage.value = true;
    const node = document.getElementById(id);
    const shareNode = document.getElementById(shareElemId);

    if (!node) {
      console.error(`Element with id ${id} not found`);
      throw new Error(`Element with id ${id} not found`);
    }

    const nodeImage = async () => {
      try {
        const result = await domToPng(node, {
          style: style[background_class],
          quality: 1,
          // debug: true,
          backgroundColor: '#000000',
          skipFonts: true,
          fetch: {
            requestInit: {
                mode: 'cors',
                cache: 'no-cache'
            },
        },
        });
        return result;
      } catch (error) {
        console.error('Error generating node image:', error);
        throw error;
      }
    };

    const sharedNodeImage = async () => {
      try {
        const targetNode = shareNode || node;
        const result = await domToPng(targetNode, {
          style: style[background_class],
          quality: 1,
          // debug: true,
          backgroundColor: '#000000',
          // scale: 2,
          skipFonts: true,
          width: imageDimensions.value?.width || 800,
          height: imageDimensions.value?.height || 418,
          fetch: {
            requestInit: {
                mode: 'cors',
                cache: 'no-cache'
            },
        },
        });
        return result;
      } catch (error) {
        console.error('Error generating shared node image:', error);
        throw error;
      }
    };

    try {
      const [nodeResult, sharedResult] = await Promise.all([
        nodeImage(),
        sharedNodeImage(),
      ]);
      imageDataUrl.value = nodeResult;
      uploadedImageUrl.value = sharedResult;
    } catch (error) {
      console.error('Error in Promise.all:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error in makePng:', error);
    loadingImage.value = false;
    throw error;
  } finally {
    loadingImage.value = false;
  }
};

const overrideXML = () => {
  const { serializeToString } = XMLSerializer.prototype;
  const clearTextBackgroundClip = (element) => {
    if (gradiantClasses.some((x) => element.classList?.contains(x))) {
      element.style.setProperty('-webkit-background-clip', 'unset');
      element.style.setProperty('--background-clip-replace', 'text');
    }
    element.childNodes.forEach((child) => {
      clearTextBackgroundClip(child);
    });
  };
  XMLSerializer.prototype.serializeToString = function (node) {
    clearTextBackgroundClip(node);
    return serializeToString
      .call(this, node)
      .replaceAll('--background-clip-replace', '-webkit-background-clip');
  };
};

export {
  makePng,
  loadingImage,
  openShareModal,
  imageDataUrl,
  imageDimensions,
  uploadedImageUrl,
};
