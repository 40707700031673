<template>
  <!-- ACCOLADES STATS -->
  <div class="py-4 font-semibold text-2xl md:py-8">Accolades</div>
  <div
    v-if="wrappedData && wrappedData.badges"
    class="grid grid-cols-1 gap-5 sm:grid-cols-4"
  >
    <BaseCard
      v-for="(item, index) in badges"
      :key="index"
      class="flex h-full w-full items-center justify-center"
      :class="`${item?.rewarded ? 'border-grid' : '!border-dashed'}`"
    >
      <div class="relative">
        <div
          class="relative mx-auto max-w-md rounded-lg py-4 shadow-md backdrop-blur-md"
          :class="`${item?.rewarded ? '' : 'text-gray-700 opacity-70'}`"
        >
          <div class="mb-2 flex justify-center">
            <div class="aspect-square h-6 w-6">
              <img :src="item?.icon || BrokenImage" class="object-contain" />
            </div>
          </div>

          <h2
            class="max-w-10/12 mb-2 text-center font-semibold xl:text-base"
            :class="`${item?.rewarded ? 'text-white' : 'text-gray-400'}`"
          >
            {{ item.title }}
          </h2>

          <p
            class="flex items-center justify-center space-x-0.5 text-center text-gray-400 text-xs"
          >
            <template v-if="item.rewarded">
              <span class="" v-html="item.description"></span>
            </template>
            <template v-else>
              <span class="" v-html="`If ${item.description.replace(/^You/, 'you')}`"></span>
            </template>
            <a href="#" class="ml-1 text-blue-400 underline"></a>
          </p>

          <div class="absolute top-2 right-0" v-if="item.rewarded == 1">
            <span
              class="rounded-md bg-[#3BD2A6] px-2 py-1 text-[10px] font-medium text-black"
            >
              Achieved
            </span>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
  <div v-else class="flex h-72 flex-col items-center justify-center">
    <NoDataFound></NoDataFound>
  </div>
</template>
<script setup>
import { inject, computed } from 'vue';
import BaseCard from '../../Shared/BaseCard.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import BrokenImage from '../../images/BrokenImage.svg';
const wrappedData = inject('wrapped-data');
const badges = computed(() => {
  return wrappedData.value?.badges
    .slice()
    .sort((a, b) => b.rewarded - a.rewarded);
});
</script>
