<template>
  <div
    class="w-full items-center justify-center space-y-2 py-4 md:flex md:justify-between md:space-y-0 md:py-8"
  >
    <div class="font-semibold text-2xl">
      Your 2024 Avalanche
      <span class="wrapped-gradient bg-clip-text text-transparent">
        Wrapped
      </span>
    </div>
    <Button
      css-classes="relative wrapped-gradient p-[.8px] rounded-sm   sm:left-0"
      @click="playWrapped = true"
    >
      <div
        class="hover:wrapped-gradient flex items-center justify-between rounded-sm bg-blue-950 py-1 px-3 text-sm hover:text-black"
      >
        <span class="px-1">PLAY MY WRAPPED</span>
        <PlayIcon class="h-4 w-4 font-semibold" />
      </div>
    </Button>
    <WrappedModal :show="playWrapped" @close="playWrapped = false" />
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import Button from '../../Shared/Button.vue';
import { PlayIcon } from '@heroicons/vue/24/outline';
import WrappedModal from '../../Shared/Wrapped/WrappedModal.vue';

const playWrapped = ref(false);
</script>
