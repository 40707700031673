<template>
  <div
    id="chill-factor-score-card"
    class="relative w-full rounded-2xl"
    :class="currentSnapShot?.current_snapshot?.score ? '!bg-primary' : ''"
  >
    <BaseCard
      :class="[
        currentSnapShot?.current_snapshot?.score
          ? `${setBgScoreBaseColor(currentSnapShot?.current_snapshot?.score)}`
          : 'default_mcf_bg',
        `${currentSnapShot?.current_snapshot?.tier?.toLowerCase()}-border`,
      ]"
      class="h-full"
    >
      <div class=" pt-6 font-normal">
        <div class="font-semibold">
          <div class="flex items-center justify-between">
            <div class="flex items-center text-[#F3F2F6] text-xl">
              Chill Factor
              <Tooltip :text="chillFactorTooltipText" class="ml-1" v-if="false">
                <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
              </Tooltip>
              <IconLoading class="w-1/12" v-if="loading" />
            </div>
            <div
              class="flex items-center justify-center"
              v-if="!loading && showProgress"
            >
              <div
                :class="[
                  !imageLoading ? 'flex' : 'flex',
                  'included items-center space-x-2 divide-x divide-[#F3F2F629] transition-all duration-300 ease-out',
                ]"
              >
                <div>
                  <img
                    class="h-2.5"
                    src="../../images/frosty_logo.svg"
                    alt="Frosty Metrics Logo"
                  />
                </div>
                <div class="px-2">
                  <img
                    class="h-2.5"
                    src="../../images/the_tie_logo.svg"
                    alt="The Tie Logo"
                  />
                </div>
              </div>
              <div
                v-if="currentSnapShot?.current_snapshot"
                class="exclude flex-shrink-0 hover:bg-white/10"
                :class="[
                  imageLoading ? 'pointer-events-none p-2' : 'cursor-pointer',
                  'rounded',
                  ,
                  `${currentSnapShot?.current_snapshot?.tier?.toLowerCase()}-border`,
                ]"
                @click.stop="shareOnTwitter"
              >
                <IconLoading
                  v-if="imageLoading"
                  class="h-4 w-4 text-gray-100"
                />
                <Button class="px-1 text-whiteSmokeAlpha-88 text-xs" v-else
                  >X (Twitter)</Button
                >
              </div>
            </div>
          </div>
          <div
            class="font-light text-neutral-400 text-xs"
            v-if="currentSnapShot?.most_recent_metrics?.last_updated"
          >
            Last Recorded C-Chain Activity:
            {{
              new Date(
                currentSnapShot?.most_recent_metrics.last_updated,
              ).toLocaleDateString()
            }}
          </div>
          <div v-else>
            <span class="font-light text-neutral-400 text-xs">
              <span>Last Recorded C-Chain Activity:</span> --
            </span>
          </div>
        </div>
        <div class="font-bold text-4xl" :class="`${changeInPercentage ? 'py-4' : 'pt-4'}`">
            <span
              v-if="currentSnapShot?.current_snapshot?.score"
              :class="`${currentSnapShot?.current_snapshot?.tier?.toLowerCase()}-text-gradient flex items-center` "
            >
              {{
                simpleNumberFormatter(currentSnapShot?.current_snapshot.score)
              }}
              <span class="font-light text-base items-center justify-center px-2 " v-if="changeInPercentage"> You have increased your Chill Factor by <span class="font-semibold" >{{ changeInPercentage }}%</span> this year. </span>
            </span>
            <span v-else-if="loading">
              <span class="default_mcf_text">0</span>
            </span>
            <span v-else-if="!loading" class="flex items-center space-x-2">
              <span class="default_mcf_text text-4xl">0</span>
              <span class="flex font-light text-red-500 text-sm">
                <img src="../../images/warning.svg" class="px-2" /> NO WALLET
                ADDRESS CONNECTED</span
              >
            </span>
          </div>
      </div>
      <div
        class="h-full w-full justify-between space-y-4 pb-4 lg:flex lg:items-end lg:space-y-0 lg:space-x-3"
      >
        <div
          class="w-full"
          :class="wrappedCss ? `${wrappedCss.stats}` : 'lg:w-1/3'"
        >
          <div
            class="border-1 box-shadow relative flex h-auto w-full flex-col overflow-hidden rounded-lg border border-gray-500/30 border-opacity-60 bg-gray-600 bg-transparent bg-opacity-10 font-normal backdrop-blur-3xl text-base"
          >
            <div class="flex flex-col text-purple-100/80">
              <div class="overflow-hidden">
                <ChillFactorStats
                  :my-address-status="currentSnapShot?.current_snapshot"
                  :current-snapshot="currentSnapShot"
                  :loading="loading"
                  :filtered-rank="rank"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Tier Progress Bar & Scaling -->
        <TierProgressBar
          v-if="showProgress"
          :current-snap-shot="currentSnapShot"
          :checkpoint-stats-historical="checkpointStatsHistorical"
          :wrappedCss="wrappedCss"
          class="w-full text-xs lg:w-2/3 xl:h-full"
        />
      </div>
      <div
        class="absolute mt-14 h-[20rem] w-full opacity-40 md:h-full md:w-6/12"
        :class="`${currentSnapShot?.current_snapshot?.score ? '!w-full' : ''}`"
      >
        <template
          v-if="
            currentSnapShot?.current_snapshot?.tier?.toLowerCase() == 'warm' &&
            !loading
          "
        >
          <div v-for="index in 2" :key="index">
            <RainAnimation />
          </div>
        </template>

        <template
          v-else-if="
            currentSnapShot?.current_snapshot?.tier?.toLowerCase() != 'warm' &&
            !loading
          "
        >
          <div v-for="index in 2" :key="index">
            <SnowAnimation />
          </div>
        </template>
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import {
  setScoreBaseTextColor,
  simpleNumberFormatter,
  setBgScoreBaseColor,
  setMinMaxTierCutOff,
  setTier
} from '../../composeables/filters';
import BaseCard from '../../Shared/BaseCard.vue';
import Tooltip from '../../Shared/Tooltip.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import IconLoading from '../../icons/IconLoading.vue';
import Button from '../../Shared/Button.vue';
import ChillFactorStats from './ChillFactorStats.vue';
import TierProgressBar from './TierProgressBar.vue';
import SnowAnimation from '../../Shared/SnowAnimation.vue';
import RainAnimation from '../../Shared/RainAnimation.vue';

const props = defineProps({
  imageLoading: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  currentSnapShot: {
    type: Object,
    default: {},
  },
  rank: {
    type: String,
  },
  checkpointStatsHistorical: {
    type: Object,
    default: {},
  },
  wrappedCss: {
    type: Object,
    default: {},
  },
  showProgress: { type: Boolean, default: true },
  changeInPercentage: {
    type: String,
    default: ''
  }
});
const emit = defineEmits(['share-on-twitter']);

function shareOnTwitter() {
  emit('shared');

}
</script>
