<template>
  <ChillfactorWidget
    :loading="loading"
    :current-snap-shot="currentSnapShot"
    :rank="{}"
    :checkpoint-stats-historical="checkpointStatsHistorical"
    :wrapped-css="wrappedCss"
    :show-progress="false"
    class="w-full"
  />
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { calculateCurrentSnapShot } from '../../../composeables/filters';
import { ChillFactorApi } from '../../../Pages/ChillFactor/ChillFactorApi';
import ChillfactorWidget from '../../../Pages/ChillFactor/ChillfactorWidget.vue';

const api = new ChillFactorApi();

const loading = ref(false);
const checkpointStatsHistorical = ref([]);

async function fetchAddress() {
  loading.value = true;
  checkpointStatsHistorical.value = await api.fetchStatsHistoricalData();
}
onMounted(() => {
  fetchAddress();
});
const currentSnapShot = computed(() => {
  let formatterSnapshot = calculateCurrentSnapShot(
    checkpointStatsHistorical.value,
  );
  loading.value = false;
  return formatterSnapshot;
});

const wrappedCss = ref({
  stats: 'w-full',
  bars: '2xl:w-44 w-14',
});
</script>
