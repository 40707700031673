<template>
  <div
    class="box-shadow relative flex flex-col overflow-hidden rounded-lg border border-grid p-1 text-base font-normal text-textColor"
    style="background: rgba(82, 104, 184, 0.04)"
  >
    <div class="p-2 text-xs">
      <div class="h-full">
        <div class="flex items-center space-x-2 py-1">
          <img
            v-if="data.data?.icon || data.data?.image || data.data?.game_api"
            :src="
              data.data?.icon ||
              data.data?.image ||
              getGameIcon(
                convertUnderScoreToDash(data.data?.game_api),
                projectImagesBaseLink,
              )
            "
            class="h-10 w-10 rounded-full"
          />
          <div
            v-else
            class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-400 text-white"
          ></div>
          <Tooltip
            :text="
              capitalizeWords(data?.data?.L1_title || data?.mapping_id, '_')
            "
            v-if="(data?.data?.L1_title || data?.mapping_id).length > 12"
          >
            <div class="text-base font-semibold text-white">
              {{
                capitalizeWords(
                  data?.data?.L1_title || data?.mapping_id,
                  '_',
                ).slice(0, 12) +
                (capitalizeWords(data?.data?.L1_title || data?.mapping_id, '_')
                  .length > 12
                  ? '...'
                  : '')
              }}
            </div>
          </Tooltip>
          <div v-else class="text-base font-semibold text-white">
            {{
              capitalizeWords(
                data?.data?.L1_title || data?.mapping_id,
                '_',
              ).slice(0, 12) +
              (capitalizeWords(data?.data?.L1_title || data?.mapping_id, '_')
                .length > 12
                ? '...'
                : '')
            }}
          </div>
        </div>
        <div class="font-sourceSans py-1 font-sourceCodePro text-xxs">
          <div
            v-for="metric in data?.data?.metrics || metrics"
            :key="Object.keys(metric)[0]"
            class="flex justify-between border-b border-gray-700 border-opacity-60 py-2"
          >
            <span>{{ Object.keys(metric)[0] }}</span>
            <span>{{
              data && data.data ? formatEntity(metric, data) : '-'
            }}</span>
          </div>
          <div class="h-full pt-2">
            <div class="font-medium text-white">Description:</div>
            <div
              class="w-full overflow-hidden text-ellipsis whitespace-normal text-xxs"
            >
              <Tooltip
                :text="data?.description?.replace(/_/g, ' ')"
                v-if="data?.description && data?.description.length > 40"
              >
                <p
                  class="flex items-start overflow-hidden text-left capitalize"
                  :class="
                    component && !data?.data?.metrics
                      ? 'h-[4.5rem]'
                      : 'h-[2.5rem]'
                  "
                >
                  {{
                    data?.description?.replace(/_/g, ' ').slice(0, 60) + '...'
                  }}
                </p>
              </Tooltip>
              <div v-else>
                <p
                  class="flex items-start overflow-hidden text-left capitalize"
                  :class="
                    component && !data?.data?.metrics
                      ? 'h-[4.5rem]'
                      : 'h-[2.5rem]'
                  "
                >
                  {{ data?.description?.replace(/_/g, ' ') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-1 text-xs">
          <a
            :href="data?.link || data?.data?.url"
            class="flex cursor-pointer items-center text-blue-500"
            target="_blank"
            v-if="data?.link?.length > 0 || data?.data?.url?.length > 0"
            >Go To Page
            <ArrowUpRightIcon class="ml-2 h-4 w-4 font-semibold" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowUpRightIcon } from '@heroicons/vue/24/outline';
import {
  titleize,
  formatBigPricing,
  capitalizeWords,
  convertUnderScoreToDash,
  terminalFormatDecimals,
  decimals,
  getGameIcon,
} from '../composeables/filters.js';
import Tooltip from '../Shared/Tooltip.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const props = defineProps({
  classes: { type: String, default: '' },
  data: { type: Object, default: {} },
  metrics: { type: Array, default: ['Name', 'Price', 'Stats', 'Description'] },
  component: { type: String, default: null },
});
function formatEntity(metric, data) {
  const value = data?.data[Object.values(metric)[0]];
  const isNumber = !isNaN(value) && value !== undefined && value !== '';
  if (!isNumber) return '-';
  let formattedValue;
  let decimals = 0;
  if (metric.numberFormatter) {
    if (metric['Price']) {
      decimals = 2;
    }
    formattedValue = terminalFormatDecimals(value, decimals);
  } else {
    formattedValue = formatBigPricing(value);
  }
  const metricKeys = Object.keys(metric);
  if (metricKeys.includes('Contracts')) {
    formattedValue = terminalFormatDecimals(value);
  }
  return metric.prefix && formattedValue
    ? `${metric.prefix}${formattedValue}`
    : formattedValue;
}

const projectImagesBaseLink = computed(() => {
  return store.getters.projectImagesBaseLink;
});
</script>
