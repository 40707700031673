import axios from 'axios';
export class WrappedApi {
  async reqMethod(path, query = {}) {
    try {
      return [(await axios.get(path, { params: query })).data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }

  async fetchWrappedStats() {
    let address = localStorage.getItem('my_address')
      ? JSON.parse(localStorage.getItem('my_address'))
      : '';
    if (!address) return '';
    const [res, error] = await this.reqMethod(`/wrapped/${address}/my_wrapped`);

    return error ? error : res;
  }
}
