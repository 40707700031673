<template>
  <section>
    <div class="mx-auto flex flex-wrap pt-4">
      <div
        class="w-full flex-shrink flex-grow border-b border-blue-400/20 px-4 py-3 sm:flex sm:justify-between"
      >
        <div class="flex">
          <div class="flex pb-3 sm:pb-0 md:space-x-2.5">
            <img :src="token.icon" loading="lazy" class="h-6" v-if="token" />
            <img
              :src="
                getGameIcon(
                  convertUnderScoreToDash(project?.game_api),
                  projectImagesBaseLink,
                )
              "
              class="h-6"
              v-else
            />
            <div class="flex flex-col">
              <div class="flex space-x-2.5">
                <span
                  class="flex items-center font-semibold capitalize leading-none text-gray-100 text-2xl"
                >
                  {{
                    titleize(
                      project?.game_api
                        ? convertUnderScoreToDash(project?.game_api)
                        : token.name,
                      ' ',
                    )
                  }}</span
                >
              </div>
              <span
                class="mt-1 flex font-medium text-gray-400 text-sm"
                v-if="token"
              >
                {{ token.ticker }}
              </span>
              <div class="mt-1 flex">
                <Link
                  :href="`/projects/${token.game}`"
                  v-if="token && token.game"
                  ><Button
                    ><span class="capitalize"
                      >{{ convertUnderScoreToDash(token.game) }} Game</span
                    ></Button
                  ></Link
                >
                <a
                  v-else-if="subnet.link"
                  class="flex items-center space-x-1 font-medium text-gray-400 text-sm"
                  :href="subnet.link"
                >
                  <span> {{ titleize(subnet.name, ' ') }}</span>
                  <IconExternalLink class="w-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center space-x-4">
          <div class="flex items-center justify-end">
            <div class="mr-4 space-x-2.5" v-if="token">
              <span class="font-semibold text-gray-100 text-xl"
                >${{ formatNumber(token.price, 6) }}</span
              >
              <span
                class="font-medium text-lg"
                :class="
                  token.price_return_24_hours > 0
                    ? 'text-green-500'
                    : 'text-red-800'
                "
                >{{ percentage(token.price_return_24_hours) }}</span
              >
            </div>
            <!-- <PlayButton /> -->
          </div>
          <SocialIcons :links="token ? token.social_icons : {}" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import Tooltip from '../Shared/Tooltip.vue';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import { Link } from '@inertiajs/inertia-vue3';
import Button from './Button.vue';
import PlayButton from './PlayButton.vue';
import IconCoin from '../icons/IconCoin.vue';
import {
  rounded,
  percentage,
  formatNumber,
  convertUnderScoreToDash,
  getGameIcon,
  titleize
} from '../composeables/filters.js';
import { ref, markRaw, computed } from 'vue';
import { useStore } from 'vuex';
import SocialIcons from './SocialIcons.vue';
import IconExternalLink from '../icons/IconExternalLink.vue';
import BaseCard from '../Shared/BaseCard.vue';
const store = useStore();

const props = defineProps({
  token: {
    type: Object,
    default: null,
  },
  project: {
    type: Object,
    default: null,
  },
});

const projectImagesBaseLink = store.getters.projectImagesBaseLink;
const subnet = computed(() => {
  let data = { name: '', link: '' };
  if (props.project) {
    if (props.project.game_api == 'crabada') {
      data = {
        name: 'Swimmer Subnet',
        link: 'https://subnets.avax.network/swimmer',
      };
    } else if (props.project.game_api == 'defi kingdoms') {
      data = {
        name: 'DFK Subnet',
        link: 'https://subnets.avax.network/defi-kingdoms',
      };
    } else {
      data = {
        name: 'C-Chain',
        link: 'https://subnets.avax.network/c-chain',
      };
    }
  }
  return data;
});
</script>
