<template>
  <div class="flex h-full flex-col">
    <div v-if="contracts.length == 0" class="flex w-full justify-center">
      <NoDataFound />
    </div>
    <div v-else class="h-full w-full overflow-x-auto">
      <div class="flex w-full flex-col text-sm text-white">
        <div
          v-for="(item, index) in contracts"
          :key="item"
          class="flex items-center gap-2 py-2"
          :class="[
            {
              'border-b-[1px] border-blue-800': index < contracts.length - 1,
            },
          ]"
        >
          <div class="text-sm font-normal">
            {{ index + 1 }}
          </div>
          <div
            class="flex max-w-72 items-center space-x-1 truncate font-normal"
          >
            <div class="flex-shrink-0">
              <img
                :src="item.icon || BrokenImage"
                class="h-6 w-6 rounded-full object-cover"
              />
            </div>
            <div class="truncate px-1">
              <a
                :href="item.link"
                target="_blank"
                class="block truncate text-base capitalize leading-none text-white"
              >
                {{
                  item?.entity_name?.replaceAll('_', ' ') ||
                  item.contract_address
                }}
              </a>
              <span
                v-if="item.transaction_count"
                class="mt-0.5 block text-xs text-gray-400"
              >
                {{ rounded(item.transaction_count, 0) }} transactions sent
              </span>
            </div>
          </div>
          <div class="ml-auto max-w-8 flex-shrink-0">
            <a :href="item.link" target="_blank">
              <ArrowTopRightOnSquareIcon class="h-4 w-4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, inject } from 'vue';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';
import { rounded } from '../../composeables/filters';
import NoDataFound from '../../load_templates/NoDataFound.vue';
const wrappedData = inject('wrapped-data');
import BrokenImage from '../../images/BrokenImage.svg';
const contracts = computed(() => {
  return wrappedData.value?.contracts;
});
</script>
