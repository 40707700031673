<template>
  <div
    class="relative flex h-full items-center rounded border-[1px] border-[#FFFFFF3D] px-2"
  >
    <div
      class="flex h-full w-48 cursor-pointer space-x-2 bg-transparent font-normal outline-none text-xs"
      @click="focusInput()"
      @focus="focusInput()"
    >
      <MagnifyingGlassIcon class="w-4 text-whiteSmokeAlpha-24" />
      <input
        class="w-full cursor-pointer bg-transparent placeholder-whiteSmokeAlpha-88 outline-none"
        placeholder="Search"
      />
    </div>
    <div v-if="searchMode" class="relative">
      <div class="z-51 fixed inset-0" @click="closeSearch()"></div>
      <div
        style="z-index: 9999"
        class="z-51 absolute -top-8 right-0 h-auto w-[25rem] rounded bg-secondary py-1 shadow-lg backdrop-blur-[12px] sm:w-[34rem] sm:bg-[#6BC1FF1F]"
      >
        <div class="flex items-center justify-between px-4 py-1">
          <div
            class="mr-4 flex flex-1 items-center rounded bg-gray-900/25 px-2 font-semibold text-whiteSmokeAlpha-88"
          >
            <MagnifyingGlassIcon class="w-4 text-gray-400" />
            <input
              class="h-8 w-full border-none bg-transparent pl-2 outline-none text-xs focus:border-none"
              placeholder="Search DeFis, Gaming, NFTs & Tokens"
              :id="inputId"
              v-model="searchPhrase"
              @focus="searchFocused = true"
              data-id="search-input"
            />
          </div>
          <div class="items-end">
            <div
              class="flex h-4 w-4 cursor-pointer items-center"
              @click="closeSearch()"
            >
              <XMarkIcon />
            </div>
          </div>
        </div>

        <div
          v-if="
            searchPhrase &&
            (computedGames?.length ||
              computedTokens?.length ||
              computedNfts?.length ||
              computedDeFiTokens?.length)
          "
        >
          <div class="flex items-center">
            <div class="flex items-center justify-center px-6 py-1">
              <BaseTags
                :tags="filteredTags"
                :selected="[selectedTag]"
                @toggle="updateSelectedTag($event)"
                :tag-bg-class="'bg-gray-700/50'"
              />
            </div>
          </div>
          <div
            :class="`${
              displayAllGames ||
              displayAllTokens ||
              displayAllNfts ||
              displayAllDeFiTokens
                ? 'max-h-80 overflow-y-auto'
                : 'max-h-104'
            }`"
          >
            <!-- DeFi -->
            <div
              class="flex items-center px-4 py-1"
              v-if="
                computedDeFiTokens?.length &&
                !displayAllGames &&
                !displayAllNfts &&
                !displayAllTokens
              "
            >
              <div class="w-full">
                <div class="justify-left flex items-center space-x-1 px-2">
                  <div class="w-48 font-medium text-gray-400 text-xs">
                    DeFis
                  </div>
                  <div
                    class="mb-2 mt-4 w-24 items-center space-x-1 whitespace-nowrap font-medium text-gray-400 text-xs"
                  >
                    Price
                  </div>
                  <div
                    class="mb-2 mt-4 w-24 items-center space-x-1 whitespace-nowrap font-medium text-gray-400 text-xs"
                  >
                    Price Change 24hr
                  </div>
                </div>
                <div class="max-h-36 space-y-1 overflow-auto">
                  <div
                    id="defi_tokens"
                    :data-dropdown-item="idx"
                    :data-link="`/defi/${deFi.ticker}`"
                    class="flex cursor-pointer items-center justify-between space-x-1 rounded px-1 py-1.5 text-white duration-100 hover:bg-blue-900"
                    :class="`${
                      focusedItem == idx ? 'element-hover bg-blue-900' : ''
                    }`"
                    v-for="(deFi, idx) in computedDeFiTokens"
                    :key="deFi.ticker"
                    :limit="
                      computedNfts?.length +
                      computedGames?.length +
                      computedDeFiTokens?.length +
                      computedTokens?.length
                    "
                    @focus="focusedItem = idx"
                    @click="
                      selectItem(`/defi/${deFi.ticker}`, deFi, 'defi_tokens')
                    "
                  >
                    <div class="flex w-full items-center space-x-2">
                      <div class="flex w-48 cursor-pointer items-center">
                        <img
                          :src="deFi.icon"
                          class="ml-2 h-4 w-4"
                          loading="lazy"
                          v-if="isValidUrl(deFi.icon)"
                        />
                        <span
                          class="ml-2 h-4 w-4 rounded-full bg-gray-700/50"
                          v-else
                        ></span>
                        <span
                          v-if="deFi.name"
                          class="pl-2 font-medium text-sm"
                          >{{ titleize(deFi.name, ' ') }}</span
                        >
                      </div>
                      <div class="mt-0.5 w-24 font-medium text-white text-xs">
                        {{ decimals(deFi.price, 2) }}
                      </div>
                      <div class="mt-0.5 w-24 font-medium text-white text-xs">
                        <Percent
                          :value="deFi?.price_return_24_hours"
                          :no-multiply="true"
                        />
                      </div>
                    </div>
                    <div
                      v-if="focusedItem == idx && width > 640"
                      class="flex items-center space-x-1"
                    >
                      <span class="font-medium text-gray-300 text-xs"
                        >Select</span
                      >
                      <div
                        class="flex h-5 w-6 items-center justify-around rounded bg-gray-700/50 px-1 py-px"
                      >
                        <ArrowLeftIcon />
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-2 cursor-pointer"
                    v-if="
                      !displayAllDeFiTokens && filteredDeFiTokens.length > 3
                    "
                  >
                    <span
                      class="text-blue-400 text-xs"
                      @click="
                        displayAllDeFiTokens = true;
                        selectedTag = 'defi_tokens';
                        focusedItem = 0;
                      "
                      >See all results ({{ filteredDeFiTokens.length }})</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- DeFi end -->
            <!-- Games -->
            <div
              class="flex items-center px-4 py-1"
              v-if="
                computedGames?.length &&
                !displayAllDeFiTokens &&
                !displayAllNfts &&
                !displayAllTokens
              "
            >
              <div class="w-full">
                <div class="items-center py-2">
                  <span class="pl-2 font-medium text-gray-400 text-xs"
                    >Gaming</span
                  >
                </div>
                <div class="max-h-36 space-y-1 overflow-auto">
                  <div
                    id="projects"
                    :data-dropdown-item="
                      !displayAllGames ? computedGames?.length + idx : idx
                    "
                    :data-link="`/projects/${game.game}`"
                    class="flex cursor-pointer items-center justify-between space-x-1 rounded px-1 py-1.5 text-white duration-100 hover:bg-blue-900"
                    :class="`${
                      (
                        !displayAllGames
                          ? focusedItem == computedGames?.length + idx
                          : focusedItem == idx
                      )
                        ? 'element-hover bg-blue-900'
                        : ''
                    }`"
                    v-for="(game, idx) in computedGames"
                    :key="idx"
                    :limit="
                      computedNfts?.length +
                      computedGames?.length +
                      computedDeFiTokens?.length +
                      computedTokens?.length
                    "
                    @focus="
                      focusedItem = !displayAllGames
                        ? computedGames?.length + idx
                        : idx
                    "
                    @click="
                      selectItem(`/projects/${game.game}`, game, 'projects')
                    "
                  >
                    <div
                      class="flex w-full items-center justify-between space-x-2"
                    >
                      <div class="flex w-48 cursor-pointer items-center">
                        <img
                          :src="
                            getGameIcon(
                              convertUnderScoreToDash(game.game_api),
                              projectImagesBaseLink,
                            )
                          "
                          class="ml-2 h-4 w-4"
                          loading="lazy"
                          v-if="
                            isValidUrl(
                              getGameIcon(
                                convertUnderScoreToDash(game.game_api),
                                projectImagesBaseLink,
                              ),
                            )
                          "
                        />
                        <span
                          class="ml-2 h-4 w-4 rounded-full bg-gray-700/50"
                          v-else
                        ></span>
                        <span
                          v-if="game.game"
                          class="pl-2 font-medium text-sm"
                          >{{ titleize(game.game, ' ') }}</span
                        >
                      </div>
                      <div
                        v-if="
                          width > 640 &&
                          (!displayAllGames
                            ? focusedItem == computedGames?.length + idx
                            : focusedItem == idx)
                        "
                        class="flex items-center space-x-1"
                      >
                        <span class="font-medium text-gray-300 text-xs"
                          >Select</span
                        >
                        <div
                          class="flex h-5 w-6 items-center justify-around rounded bg-gray-700/50 px-1 py-px"
                        >
                          <ArrowLeftIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-2 cursor-pointer"
                    v-if="!displayAllGames && filteredGames.length > 3"
                  >
                    <span
                      class="text-blue-400 text-xs"
                      @click="
                        displayAllGames = true;
                        selectedTag = 'projects';
                        focusedItem = 0;
                      "
                      >See all results ({{ filteredGames.length }})</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Games end -->
            <!-- NFTs -->
            <div
              class="flex items-center px-4 py-1"
              v-if="
                computedNfts?.length &&
                !displayAllGames &&
                !displayAllTokens &&
                !displayAllDeFiTokens
              "
            >
              <div class="w-full">
                <div class="justify-left flex items-center space-x-1 px-2">
                  <div class="w-48 font-medium text-gray-400 text-xs">NFTs</div>
                  <div
                    class="mb-2 mt-4 w-24 items-center space-x-1 whitespace-nowrap font-medium text-gray-400 text-xs"
                  >
                    Floor Price
                  </div>
                  <div
                    class="mb-2 mt-4 w-24 items-center space-x-1 whitespace-nowrap font-medium text-gray-400 text-xs"
                  >
                    FP Change 24hr
                  </div>
                </div>
                <div class="max-h-36 space-y-1 overflow-auto">
                  <div
                    id="nfts"
                    :data-dropdown-item="
                      !displayAllNfts
                        ? computedGames?.length +
                          computedDeFiTokens?.length +
                          idx
                        : idx
                    "
                    :data-link="`/nfts/${nft.address}`"
                    class="flex cursor-pointer items-center justify-between space-x-1 rounded px-1 py-1.5 text-white duration-100 hover:bg-blue-900"
                    :class="`${
                      (
                        !displayAllNfts
                          ? focusedItem ==
                            computedGames?.length +
                              computedDeFiTokens?.length +
                              idx
                          : focusedItem == idx
                      )
                        ? 'element-hover bg-blue-900'
                        : ''
                    }`"
                    v-for="(nft, idx) in computedNfts"
                    :key="nft.address"
                    :limit="
                      computedNfts?.length +
                      computedGames?.length +
                      computedDeFiTokens?.length +
                      computedTokens?.length
                    "
                    @focus="
                      focusedItem = !displayAllGames
                        ? computedGames?.length +
                          computedDeFiTokens?.length +
                          idx
                        : idx
                    "
                    @click="selectItem(`/nfts/${nft.address}`, nft, 'nfts')"
                  >
                    <div class="flex w-full items-center space-x-2">
                      <div class="flex w-48 cursor-pointer items-center">
                        <img
                          :src="nft.image_url"
                          class="ml-2 h-4 w-4"
                          loading="lazy"
                          v-if="isValidUrl(nft.image_url)"
                        />
                        <span
                          class="ml-2 h-4 w-4 rounded-full bg-gray-700/50"
                          v-else
                        ></span>
                        <span
                          v-if="nft.collection_name"
                          class="pl-2 font-medium text-sm"
                          >{{ titleize(nft.collection_name, ' ') }}</span
                        >
                      </div>
                      <div class="mt-0.5 w-24 font-medium text-white text-xs">
                        {{ decimals(nft.floor_price, 2) }}
                      </div>
                      <div class="mt-0.5 w-24 font-medium text-white text-xs">
                        <Percent
                          :value="nft?.prev_floor_pirce"
                          :no-multiply="true"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        width > 640 &&
                        (!displayAllNfts
                          ? focusedItem ==
                            computedGames?.length +
                              computedDeFiTokens?.length +
                              idx
                          : focusedItem == idx)
                      "
                      class="flex items-center space-x-1"
                    >
                      <span class="font-medium text-gray-300 text-xs"
                        >Select</span
                      >
                      <div
                        class="flex h-5 w-6 items-center justify-around rounded bg-gray-700/50 px-1 py-px"
                      >
                        <ArrowLeftIcon />
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-2 cursor-pointer"
                    v-if="!displayAllNfts && filteredNfts.length > 3"
                  >
                    <span
                      class="text-blue-400 text-xs"
                      @click="
                        displayAllNfts = true;
                        selectedTag = 'nfts';
                        focusedItem = 0;
                      "
                      >See all results ({{ filteredNfts.length }})</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- NFTs end -->
            <!-- Tokens -->
            <div
              class="flex items-center px-4 py-1"
              v-if="
                computedTokens?.length &&
                !displayAllGames &&
                !displayAllNfts &&
                !displayAllDeFiTokens
              "
            >
              <div class="w-full">
                <div class="justify-left flex items-center space-x-1 px-2">
                  <div class="w-48 font-medium text-gray-400 text-xs">
                    Tokens
                  </div>
                  <div
                    class="mb-2 mt-4 w-24 items-center space-x-1 whitespace-nowrap font-medium text-gray-400 text-xs"
                  >
                    Price
                  </div>
                  <div
                    class="mb-2 mt-4 w-24 items-center space-x-1 whitespace-nowrap font-medium text-gray-400 text-xs"
                  >
                    Price Change 24hr
                  </div>
                </div>
                <div class="max-h-36 space-y-1 overflow-auto">
                  <div
                    id="tokens"
                    :data-dropdown-item="
                      !displayAllTokens
                        ? computedGames?.length +
                          computedDeFiTokens?.length +
                          computedNfts?.length +
                          idx
                        : idx
                    "
                    :data-link="`/tokens/${company.ticker}`"
                    class="flex cursor-pointer items-center justify-between space-x-1 rounded px-1 py-1.5 text-white duration-100 hover:bg-blue-900"
                    :class="`${
                      (
                        !displayAllTokens
                          ? focusedItem ==
                            computedGames?.length +
                              computedDeFiTokens?.length +
                              computedNfts?.length +
                              idx
                          : focusedItem == idx
                      )
                        ? 'element-hover bg-blue-900'
                        : ''
                    }`"
                    v-for="(company, idx) in computedTokens"
                    :key="company.id"
                    :limit="
                      computedNfts?.length +
                      computedGames?.length +
                      computedDeFiTokens?.length +
                      computedTokens?.length
                    "
                    @focus="
                      focusedItem = !displayAllTokens
                        ? computedGames?.length +
                          computedDeFiTokens?.length +
                          computedNfts?.length +
                          idx
                        : idx
                    "
                    @click="
                      selectItem(`/tokens/${company.ticker}`, company, 'tokens')
                    "
                  >
                    <div class="flex w-full items-center space-x-2">
                      <div class="flex w-48 cursor-pointer items-center">
                        <img
                          :src="company.icon"
                          class="ml-2 h-4 w-4"
                          loading="lazy"
                          v-if="isValidUrl(company.icon)"
                        />
                        <span
                          class="ml-2 h-4 w-4 rounded-full bg-gray-700/50"
                          v-else
                        ></span>
                        <span
                          v-if="company.name"
                          class="pl-2 font-medium text-sm"
                          >{{ titleize(company.name, ' ') }}</span
                        >
                      </div>
                      <div class="mt-0.5 w-24 font-medium text-white text-xs">
                        {{ decimals(company.price, 2) }}
                      </div>
                      <div class="mt-0.5 w-24 font-medium text-white text-xs">
                        <Percent :value="company.price_return_24_hours" />
                      </div>
                    </div>
                    <div
                      v-if="
                        width > 640 &&
                        (!displayAllTokens
                          ? focusedItem ==
                            computedGames?.length +
                              computedDeFiTokens?.length +
                              computedNfts?.length +
                              idx
                          : focusedItem == idx)
                      "
                      class="flex items-center space-x-1"
                    >
                      <span class="font-medium text-gray-300 text-xs"
                        >Select</span
                      >
                      <div
                        class="flex h-5 w-6 items-center justify-around rounded bg-gray-700/50 px-1 py-px"
                      >
                        <ArrowLeftIcon />
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-2 cursor-pointer"
                    v-if="!displayAllTokens && filteredTokens.length > 3"
                  >
                    <span
                      class="text-blue-400 text-xs"
                      @click="
                        displayAllTokens = true;
                        selectedTag = 'tokens';
                        focusedItem = 0;
                      "
                      >See all results ({{ filteredTokens.length }})</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Tokens end-->
          </div>
        </div>
        <div
          v-if="
            searchPhrase &&
            !computedGames?.length &&
            !computedTokens?.length &&
            !computedNfts?.length &&
            !computedDeFiTokens?.length
          "
          class="flex flex-col items-center justify-center overflow-x-auto px-4 py-8"
        >
          <span
            class="mb-2 font-medium text-sm"
            v-if="smallCaseSearchPhrase?.length > 2"
          >
            <div class="flex justify-center">
              No results for '{{ searchPhrase }}'
            </div>
            <div class="text-center text-gray-400 text-xs">
              If we don't support Games, Tokens, NFTs & DeFi, please let us know
              - we appreciate requests.
            </div>
          </span>
          <span class="mb-2 font-medium text-sm" v-else
            >Please enter atleast 3 characters '{{ searchPhrase }}'</span
          >
        </div>
        <div
          class="mt-8 flex flex-row items-center justify-between px-4 pb-2"
          :class="
            searchPhrase &&
            !computedGames?.length &&
            !computedTokens?.length &&
            !computedNfts?.length &&
            !computedDeFiTokens?.length
              ? 'hidden'
              : ''
          "
        >
          <div class="flex flex-row items-center space-x-1">
            <div
              class="flex h-4 w-4 items-center justify-around rounded bg-gray-700/50"
            >
              <ArrowUpIcon />
            </div>
            <div
              class="flex h-4 w-4 items-center justify-around rounded bg-gray-700/50"
            >
              <ArrowDownIcon />
            </div>
            <span class="font-medium text-gray-300 text-xs">To Navigate</span>
          </div>
          <div class="flex flex-row items-center space-x-1">
            <div
              class="flex h-4 w-8 items-center justify-around rounded bg-gray-700/50"
            >
              <span class="text-xs"> esc </span>
            </div>
            <span class="font-medium text-gray-300 text-xs">To Cancel</span>
          </div>
          <div class="flex flex-row items-center space-x-1">
            <div
              class="flex h-4 w-4 items-center justify-around rounded bg-gray-700/50 text-gray-300"
            >
              <ArrowLeftIcon />
            </div>
            <span class="font-medium text-gray-300 text-xs">To Select</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PhotoIcon } from '@heroicons/vue/20/solid';
import { ref, computed, watch, inject, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { Link } from '@inertiajs/inertia-vue3';
import BaseTags from '../Shared/BaseTags.vue';
import { DashboardApi } from '../Pages/Dashboard/DashboardApi';
import {
  truncate,
  titleize,
  percentage,
  decimals,
  isValidUrl,
  convertUnderScoreToDash,
  getGameIcon,
} from '../composeables/filters.js';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/vue/20/solid';
import chikn from '../images/chikn.svg';
import crabada from '../images/crabada.svg';
import defikingdoms from '../images/defikingdoms.svg';
import gunfire from '../images/gunfire.svg';
import heroeschained from '../images/heroeschained.svg';
import openblox from '../images/openblox.svg';
import snailtrail from '../images/snailtrail.svg';
import imperiumempires from '../images/imperiumempires.svg';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import Percent from '../Shared/Percent.vue';
import { NftApi } from '../Pages/Nfts/NftApi';
const store = useStore();
const api = new NftApi();

const universeProjects = inject('universeProjects');
const universeTokens = inject('universeTokens');
const universeNFTs = inject('universeNFTs');

const width = ref();
const projectImagesBaseLink = store.getters.projectImagesBaseLink;
onMounted(() => {
  width.value = window.innerWidth;
  window.addEventListener('keydown', keydownEventBeforeOpen);
  window.addEventListener('resize', () => {
    width.value = window.innerWidth;
  });
});
onBeforeUnmount(() => {
  window.removeEventListener('keydown', keydownEventBeforeOpen);
});
const inputId = ref(`${Math.random()}`);
const searchPhrase = ref('');
const searchMode = ref(false);
const tags = ref([
  {
    id: 'all',
    title: 'All',
  },
  {
    id: 'defi_tokens',
    title: 'DeFi',
  },
  {
    id: 'projects',
    title: 'Gaming',
  },
  {
    id: 'nfts',
    title: 'NFTs',
  },
  {
    id: 'tokens',
    title: 'Tokens',
  },
]);
const selectedTag = ref('all');
const displayAllGames = ref(false);
const displayAllTokens = ref(false);
const displayAllNfts = ref(false);
const focusedItem = ref(0);
const searchFocused = ref(false);

const smallCaseSearchPhrase = computed(() => {
  return searchPhrase.value.toLowerCase();
});

const recentSearch = computed(() => {
  let recentSearch = JSON.parse(localStorage.getItem('recentSearch')) || [];
  return recentSearch
    ? recentSearch
        .filter((item) => Object.prototype.hasOwnProperty.call(item, 'name'))
        .reverse()
        .slice(0, 5)
    : recentSearch;
});

const filteredGames = computed(() => {
  let games = universeProjects.value;
  let filteredGames = games.filter((game) => {
    return game.game_api.toLowerCase().includes(smallCaseSearchPhrase.value);
  });
  return filteredGames;
});

const computedGames = computed(() => {
  let computedGames = !displayAllGames.value
    ? filteredGames.value.slice(0, 3)
    : filteredGames.value;
  updateFocusedItem(computedGames);
  return computedGames;
});

const computedNfts = computed(() => {
  let computedNfts = !displayAllNfts.value
    ? filteredNfts.value.slice(0, 3)
    : filteredNfts.value;
  updateFocusedItem(computedNfts);
  return computedNfts;
});

const filteredNfts = computed(() => {
  try {
    let searchedNfts = universeNFTs.value || [];
    let filteredNfts = searchedNfts.filter((nft) => {
      return (
        (nft.collection_name || '')
          .toLowerCase()
          .includes(smallCaseSearchPhrase.value) ||
        (nft.address || '').toLowerCase().includes(smallCaseSearchPhrase.value)
      );
    });
    return filteredNfts?.length > 0 ? filteredNfts.slice(0, 30) : [];
  } catch (error) {
    console.log(error);
    return [];
  }
});

const computedTokens = computed(() => {
  let computedTokens = !displayAllTokens.value
    ? filteredTokens.value.slice(0, 3)
    : filteredTokens.value;
  updateFocusedItem(computedTokens);
  return computedTokens;
});

const filteredTokens = computed(() => {
  let tokens = universeTokens.value;
  let filteredTokens = [];
  filteredTokens = tokens.filter((x) => {
    return (x.name || '').toLowerCase().includes(smallCaseSearchPhrase.value);
  });
  return filteredTokens;
});

//DeFi Tokens
const universeDeFiTokens = inject('universeDefiTokens');
const displayAllDeFiTokens = ref(false);
const computedDeFiTokens = computed(() => {
  let computedDeFi = !displayAllDeFiTokens.value
    ? filteredDeFiTokens.value.slice(0, 3)
    : filteredDeFiTokens.value;
  updateFocusedItem(computedDeFi);
  return computedDeFi;
});

const filteredDeFiTokens = computed(() => {
  let deFiTokens = universeDeFiTokens.value;
  let filteredTokens = [];
  filteredTokens = deFiTokens.filter((x) => {
    return (x.name || '').toLowerCase().includes(smallCaseSearchPhrase.value);
  });
  return filteredTokens;
});

const filteredTags = computed(() => {
  if (smallCaseSearchPhrase.value) {
    if (!filteredGames.value?.length && !filteredTokens.value?.length) {
      return [];
    }
    if (!filteredGames.value?.length) {
      return tags.value.filter((t) => t.id != 'projects');
    }
    if (!filteredTokens.value?.length) {
      return tags.value.filter((t) => t.id != 'tokens');
    }
    if (!filteredNfts.value?.length) {
      return tags.value.filter((t) => t.id != 'nfts');
    }
    if (!filteredDeFiTokens.value?.length) {
      return tags.value.filter((t) => t.id != 'defi_tokens');
    } else {
      return tags.value;
    }
  } else return tags.value;
});

watch(searchPhrase, () => {
  if (searchPhrase.value == '') {
    updateSelectedTag('all');
  }
});

function updateSelectedTag(tag) {
  selectedTag.value = tag;
  if (tag == 'nfts') {
    displayAllNfts.value = true;
    displayAllTokens.value = false;
    displayAllGames.value = false;
    displayAllDeFiTokens.value = false;
  }
  if (tag == 'projects') {
    displayAllGames.value = true;
    displayAllTokens.value = false;
    displayAllNfts.value = false;
    displayAllDeFiTokens.value = false;
  }
  if (tag == 'tokens') {
    displayAllTokens.value = true;
    displayAllGames.value = false;
    displayAllNfts.value = false;
    displayAllDeFiTokens.value = false;
  }
  if (tag == 'defi_tokens') {
    displayAllDeFiTokens.value = true;
    displayAllTokens.value = false;
    displayAllGames.value = false;
    displayAllNfts.value = false;
  }
  if (tag == 'all') {
    displayAllTokens.value = false;
    displayAllGames.value = false;
    displayAllNfts.value = false;
    displayAllDeFiTokens.value = false;
  }
  focusedItem.value = 0;
}

function keydownEventsAfterOpen(e) {
  if (e.target.nodeName.toLowerCase() === 'textarea') {
    return;
  }
  if (e.key === 'Escape') {
    closeSearch();
  }
  if (e.key == 'Backspace') {
    focusedItem.value = 0;
  }
  if (e.key === 'Enter') {
    let redirectLink = document
      .querySelector('[data-dropdown-item="' + focusedItem.value + '"]')
      ?.getAttribute('data-link');

    let item = '';
    let activeDivId = document
      .querySelector('[data-dropdown-item="' + focusedItem.value + '"]')
      ?.getAttribute('id');

    if (activeDivId == 'defi_tokens') {
      item = computedDeFiTokens.value
        ? computedDeFiTokens.value[focusedItem.value]
        : null;
    }
    if (activeDivId == 'projects') {
      let focusedItemIs = Math.abs(
        computedDeFiTokens.value?.length - focusedItem.value,
      );
      item = computedGames.value ? computedGames.value[focusedItemIs] : null;
    }
    if (activeDivId == 'nfts') {
      let focusedItemIs = Math.abs(
        computedTokens.value?.length +
          computedGames.value?.length -
          focusedItem.value,
      );
      item = computedNfts.value ? computedNfts.value[focusedItemIs] : null;
    }
    if (activeDivId == 'tokens') {
      let focusedItemIs = Math.abs(
        computedDeFiTokens.value?.length +
          computedGames.value?.length +
          computedNfts.value?.length -
          focusedItem.value,
      );
      item = computedTokens.value ? computedTokens.value[focusedItemIs] : null;
    }
    selectItem(redirectLink, item, activeDivId);
  }
  if (e.key === 'ArrowUp') {
    e.preventDefault();
    focusedItem.value--;
    if (focusedItem.value >= 0) {
      document
        .querySelector('[data-dropdown-item="' + focusedItem.value + '"]')
        .focus();
    } else {
      focusedItem.value = 0;
      document.querySelector('[data-id="search-input"]').focus();
    }
    fixScrolling('up');
  }
  if (e.key === 'ArrowDown') {
    e.preventDefault();
    focusedItem.value++;
    let limit = document
      .querySelector('[data-dropdown-item="' + focusedItem.value + '"]')
      ?.getAttribute('limit');
    if (limit && focusedItem.value < limit) {
      document
        .querySelector('[data-dropdown-item="' + focusedItem.value + '"]')
        .focus();
    } else {
      focusedItem.value = 0;
    }
    fixScrolling('down');
  }
}

function selectItem(redirectLink, data, type) {
  if (redirectLink && data) {
    saveSearch(redirectLink, data, type);
    window.location.href = redirectLink;
  }
}

function saveSearch(redirectLink, item, type) {
  let recentSearch = JSON.parse(localStorage.getItem('recentSearch')) || [];

  let name = '';
  let link = '';
  let icon = '';
  let base_url = window.location.origin;
  if (type == 'tokens') {
    name = item.name;
    link = base_url + '/tokens/' + item.ticker;
    icon = item.icon;
  } else if (type == 'projects') {
    name = item.game;
    link = base_url + '/projects/' + item.game;
  } else if (type == 'nfts') {
    name = item.collection_name;
    link = base_url + '/nfts/' + item.collection;
  } else if (type == 'defi_tokens') {
    name = item.name;
    link = base_url + '/defi/' + item.url;
  }
  let data = {
    name: name,
    link: link,
    type: type,
    icon: icon,
  };

  if (!recentSearch.find((item) => item['name'] === name)) {
    recentSearch.push(data);
    localStorage.setItem('recentSearch', JSON.stringify(recentSearch));
  }
}

function fixScrolling(action) {
  let hoverElem = document.querySelector('.element-hover');
  if (hoverElem !== null) {
    if (action === 'down') {
      hoverElem.nextElementSibling.scrollIntoView({ block: 'nearest' });
    } else if (action === 'up' && hoverElem.previousElementSibling) {
      hoverElem.previousElementSibling.scrollIntoView({ block: 'nearest' });
    }
  }
}
function keydownEventBeforeOpen(e) {
  if (
    !['input', 'textarea'].includes(e.target.nodeName.toLowerCase()) &&
    e.key === '/'
  ) {
    focusInput();
  }
}
function focusInput() {
  searchMode.value = true;
  searchFocused.value = true;
  setTimeout(
    () => document.querySelector('[data-id="search-input"]').focus(),
    10,
  );
  window.addEventListener('keydown', keydownEventsAfterOpen);
}
function updateFocusedItem(item) {
  if (item?.length) {
    focusedItem.value = 0;
  }
}
function closeSearch() {
  searchMode.value = false;
  searchPhrase.value = '';
  updateSelectedTag('all');
  window.removeEventListener('keydown', keydownEventsAfterOpen);
}
</script>
