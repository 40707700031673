import { DateTime } from 'luxon';
import moment from 'moment';

export function percentage(
  val,
  num_decimals = 2,
  noMultiply = false,
  showPlus = true,
  rounded = false,
) {
  if (
    typeof val === 'undefined' ||
    val === null ||
    val === '' ||
    val === 'n/a' ||
    val == 'NaN'
  ) {
    return '';
  }
  val = parseFloat(val);
  if (!noMultiply) {
    val *= 100;
  }

  let plus = '+';
  plus = showPlus ? plus : '';

  if (rounded) {
    val = Math.round(val);
  }
  return val > 0
    ? `${plus + decimals(val, num_decimals, num_decimals)}%`
    : `${decimals(val, num_decimals, num_decimals)}%`;
}
export function localDateEqualToUtc() {
  format = 'YYYY-MM-DD';
  return moment().utc().format(format) === moment().local().format(format);
}
export function formatNumber(num, digits = 2, prefix = '') {
  if (
    typeof num === 'undefined' ||
    num === null ||
    num === '' ||
    num == 0 ||
    num == 0.0
  ) {
    return '0';
  }
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  var negative = [
    { value: -1, symbol: '' },
    { value: -1e3, symbol: 'k' },
    { value: -1e6, symbol: 'M' },
    { value: -1e9, symbol: 'B' },
    { value: -1e12, symbol: 'T' },
    { value: -1e15, symbol: 'P' },
    { value: -1e18, symbol: 'E' },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  if (num >= 0) {
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
  } else {
    for (i = si.length - 1; i > 0; i--) {
      if (num <= negative[i].value) {
        break;
      }
    }
  }
  let value = (num / si[i].value).toFixed(digits).replace(rx, '$1');
  if (value < 0) {
    value = '-' + prefix + Math.abs(value);
  } else {
    value = prefix + value;
  }
  return value + si[i].symbol;
}

export function decimals(value, minDecimals = 4, maxDecimals = 4) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  }).format(value);
}

export function terminalFormatDecimals(
  value,
  minDecimals = 0,
  maxDecimals = 6,
) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }
  value = parseFloat(value);
  if (value < 1) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: maxDecimals,
    }).format(value);
  } else {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: minDecimals,
    }).format(value);
  }
}
export function getRandomId() {
  return Math.floor(Math.random() * 100000000)
    .toString(36)
    .substr(0, 8);
}

export function truncate(value, maxChars) {
  if (value && value.length > maxChars) {
    return value.slice(0, maxChars) + '...';
  }
  return value;
}

export function toDateTime(timestamp, format = '') {
  let datetime = '';
  if (typeof timestamp === 'string') {
    datetime = DateTime.fromISO(timestamp);
  } else {
    datetime = DateTime.fromJSDate(timestamp);
  }
  if (datetime > DateTime.now().startOf('day')) {
    if (format === 'simple') {
      return datetime.toLocaleString(DateTime.TIME_SIMPLE);
    } else {
      return 'Today at ' + datetime.toLocaleString(DateTime.TIME_SIMPLE);
    }
  } else {
    return datetime.toFormat('MMM dd, ') + datetime.toFormat('h:mm a');
  }
}

export function roundSmallPercent(value, minDecimals = 2, maxDecimals = 4) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }
  if (value < 0.01) {
    minDecimals = 4;
    maxDecimals = 4;
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  }).format(value);
}

export function rounded(value, decimalPlaces = 2) {
  if (
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    isNaN(value)
  ) {
    return '';
  }
  var decimalNumber = typeof value === 'string' ? parseFloat(value) : value;

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(decimalNumber);
}

// NumericalDate YYYY-MM-DD
export function numericalDate(date) {
  if (!date) {
    return '';
  }
  return moment.utc(date).format('YYYY-MM-DD');
}

export function readableDate(date) {
  if (!date) {
    return '';
  }
  return moment(date).format('MMM D, YYYY');
}

export function readableDateTime(date) {
  if (!date) {
    return '';
  }
  return moment(date).format('LLLL');
}

export function timeWithinToday(date) {
  return moment(date).format('YYYY-MM-DD h:mma');
}

export function minimalTimeWithinToday(date) {
  //return empty if null
  if (!date) {
    return '';
  }
  // if today then remove YYYY-MM-DD
  if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
    return moment(date).format('h:mma');
  }

  // if same year, remove the year
  if (moment(date).format('YYYY') === moment().format('YYYY')) {
    return moment(date).format('MMM D, h:mma');
  }

  return moment(date).format('lll');
}

export function relativeTime(date) {
  return moment.utc(date).fromNow();
}

export function formatPrice(num) {
  const dec = num > 1 ? 100 : 1000000;
  return (Math.round(Number(num) * dec) / dec).toLocaleString(undefined, {
    minimumFractionDigits: num > 1 ? 0 : 6,
  });
}

export function slugify(str, separator = '-') {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, separator);
}

export function roundedBtc(value, decimalPlaces = 2) {
  if (
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    isNaN(value)
  ) {
    return '';
  }
  var decimalNumber = typeof value === 'string' ? parseFloat(value) : value;

  if (value < 1) {
    decimalPlaces = 6;
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(decimalNumber);
}

export function titleize(str, separator = '-') {
  return str
    ?.split(separator)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(' ');
}

export function upperCase(str, separator = '-') {
  return str
    .split(separator)
    .map((word) => {
      return word.toUpperCase();
    })
    .join(' ');
}

export function capitalizeWords(str, separator = ' ') {
  return str
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function formatPricing(n) {
  if (typeof n === 'undefined' || n === null) return '';
  return n.toLocaleString();
}

export function formatBigPricing(n) {
  if (typeof n === 'undefined' || n === null) return '';
  n = parseFloat(n || 0);
  let isPositive = n && n >= 0;
  if (!isPositive) {
    n = -n;
  }
  if (n < 1e3) return (isPositive ? n : -n).toLocaleString();
  if (n >= 1e3 && n < 1e6)
    return +((isPositive ? n : -n) / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9)
    return +((isPositive ? n : -n) / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12)
    return +((isPositive ? n : -n) / 1e9).toFixed(1) + 'B';
  if (n >= 1e12 && n < 1e15)
    return +((isPositive ? n : -n) / 1e12).toFixed(1) + 'T';
  if (n >= 1e15 && n < 1e18)
    return +((isPositive ? n : -n) / 1e15).toFixed(1) + 'q';
  if (n >= 1e18 && n < 1e21)
    return +((isPositive ? n : -n) / 1e18).toFixed(1) + 'Q';
  if (n >= 1e21 && n < 1e24)
    return +((isPositive ? n : -n) / 1e21).toFixed(1) + 's';
  if (n >= 1e24 && n < 1e27)
    return +((isPositive ? n : -n) / 1e24).toFixed(1) + 'S';
  if (n >= 1e27 && n < 1e30)
    return +((isPositive ? n : -n) / 1e27).toFixed(1) + 'o';
  if (n >= 1e30 && n < 1e33)
    return +((isPositive ? n : -n) / 1e30).toFixed(1) + 'n';
}

export function formatPercent(n) {
  if (typeof n === 'undefined' || n === null) return '';
  n = parseFloat(n);
  return (
    (n < 0
      ? n.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '+' +
        n.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })) + '%'
  );
}

export function humanReadableToNumber(n) {
  if (typeof n === 'undefined' || n === null) return '';
  n = n.replace('$', '').toLowerCase();
  if (n.includes('k')) return parseFloat(n) * 1e3;
  if (n.includes('m')) return parseFloat(n) * 1e6;
  if (n.includes('b')) return parseFloat(n) * 1e9;
  if (n.includes('t')) return parseFloat(n) * 1e12;
  if (n.includes('p')) return parseFloat(n) * 1e15;
  if (n.includes('e')) return parseFloat(n) * 1e18;
  else return n;
}

export function filterToDateTime(str, onlynumbs = false, current_date = null) {
  let [numbs, chars] = str.match(/\D+|\d+/g);
  if (onlynumbs) {
    if (chars) {
      return chars.toLowerCase() == 'd'
        ? numbs
        : chars.toLowerCase() == 'm'
        ? numbs * 30
        : numbs * 365;
    } else {
      return 'ALL';
    }
  } else {
    if (chars?.length > 0) {
      if (current_date) {
        return moment(current_date)
          .subtract(
            parseInt(numbs),
            chars.toLowerCase() == 'd'
              ? 'days'
              : chars.toLowerCase() == 'm'
              ? 'months'
              : 'years',
          )
          .utc()
          .format();
      } else {
        return moment()
          .subtract(
            parseInt(numbs),
            chars.toLowerCase() == 'd'
              ? 'days'
              : chars.toLowerCase() == 'm'
              ? 'months'
              : 'years',
          )
          .utc()
          .format();
      }
    }
  }
}

export function isValidUrl(urlString) {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export function timeToUnix(datetime, convertLocal = false) {
  let local = moment().format('YYYY-MM-DD');
  let utc = moment.utc().format('YYYY-MM-DD');
  let time = moment(datetime).unix() * 1000;
  if (convertLocal && local < utc) {
    let utc = moment.utc(datetime).toDate();
    time = moment(utc).local().unix() * 1000;
  }
  return time;
}

export function colors() {
  return [
    '#60A5FA',
    '#F87171',
    '#34D399',
    '#FBBF24',
    '#E879F9',
    '#2563EB',
    '#047857',
    '#3B82F6',
    '#C026D3',
    '#10B981',
    '#D946EF',
    '#065F46',
    '#F59E0B',
  ];
}
export function coinBaseColors(coin) {
  return {
    'step app': '#34D399',
    'defi kingdoms': '#60A5FA',
    betswirl: '#D0D3D4',
    'open blox': '#B2FF33',
    'heroes chained': '#839192',
    chikn: '#C026D3',
    crabada: '#F87171',
    'imperium empires': '#F59E0B',
    'pizza game': '#047857',
    'crypto royale': '#3B82F6',
    'snail trail': '#FBBF24',
    gunfire: '#FF3346',
    'battle for giostone': '#92400e',
    beam: '#B76734',
    betswirl: '#c026d3',
    domi: '#4c0519',
    dos: '#a855f7',
    'dragon crypto': '#FA8072',
    'hatchy pocket': '#fde047',
    megaweapon: '#94a3b8',
    shrapnel: '#C48793',
    'gunzilla testnet': '#67E8F9',
    pulsar: '#A464E4',
  }[coin];
}

export function calculateChange(newPrice, oldPrice) {
  newPrice = parseFloat(newPrice) || 0;
  oldPrice = parseFloat(oldPrice) || 0;
  return oldPrice == 0 ? 0 : (newPrice - oldPrice) / oldPrice;
}

export function convertUnderScoreToDash(value, separator = '_') {
  return value == 'openblox' ? 'open blox' : value.replace(/_/g, ' ');
}

export function filtersArray(param) {
  const filterValues = [
    { id: '24H', value: '1D' },
    { id: '7D', value: '7D' },
    { id: '30D', value: '30D' },
    { id: '3M', value: '90D' },
    { id: '6M', value: '180D' },
    { id: '1Y', value: '365D' },
    { id: 'ALL', value: '1825D' },
  ];
  return filterValues.find((item) => item.id == param)?.value;
}

export function findMyStats(myAddress, data) {
  if (data && Object.keys(data).length > 0) {
    let mergedValues = [].concat(...Object.values(data));
    return mergedValues?.find((item) => item?.address == myAddress) || {};
  } else {
    return {};
  }
}

export function setScoreBaseColor(score) {
  let tierCutOff = JSON.parse(localStorage.getItem('tier_cutoff'));
  if (score && tierCutOff)
    return score > -9999 && score <= tierCutOff['lukeWarmMin']
      ? 'bg-orange-500'
      : score > tierCutOff['lukeWarmMin'] && score <= tierCutOff['chillyMin']
      ? 'bg-amber-400'
      : score > tierCutOff['chillyMin'] && score <= tierCutOff['frozenMin']
      ? 'bg-cyan-400'
      : score > tierCutOff['frozenMin'] && score <= tierCutOff['subzeroMin']
      ? 'bg-[#6E87E3]'
      : score > tierCutOff['subzeroMin']
      ? 'bg-[#060023]'
      : '';
}

export function setTier(score) {
  let tierCutOff = JSON.parse(localStorage.getItem('tier_cutoff'));
  if (score && tierCutOff)
    return score > -9999 && score <= tierCutOff['lukeWarmMin']
      ? 'WARM'
      : score > tierCutOff['lukeWarmMin'] && score <= tierCutOff['chillyMin']
      ? 'LUKEWARM'
      : score > tierCutOff['chillyMin'] && score <= tierCutOff['frozenMin']
      ? 'CHILLY'
      : score > tierCutOff['frozenMin'] && score <= tierCutOff['subzeroMin']
      ? 'FROZEN'
      : score > tierCutOff['subzeroMin']
      ? 'SUBZERO'
      : '';
}

export function setScoreBaseTextColor(score) {
  let tierCutOff = JSON.parse(localStorage.getItem('tier_cutoff'));
  if (score && tierCutOff)
    return score > -9999 && score <= tierCutOff['lukeWarmMin']
      ? 'text-[#FDBA74]'
      : score > tierCutOff['lukeWarmMin'] && score <= tierCutOff['chillyMin']
      ? 'text-[#FCD34D]'
      : score > tierCutOff['chillyMin'] && score <= tierCutOff['frozenMin']
      ? 'text-[#5EEAD4]'
      : score > tierCutOff['frozenMin'] && score <= tierCutOff['subzeroMin']
      ? 'text-[#A9B9F1]'
      : score > tierCutOff['subzeroMin']
      ? 'text-[#D79BFF]'
      : '';
}

export function setBgScoreBaseColor(score) {
  let tierCutOff = JSON.parse(localStorage.getItem('tier_cutoff'));
  if (!score) return 'default_bg';
  if (score && tierCutOff)
    return score > -9999 && score <= tierCutOff['lukeWarmMin']
      ? 'bg_warm'
      : score > tierCutOff['lukeWarmMin'] && score <= tierCutOff['chillyMin']
      ? 'bg_lukewarm'
      : score > tierCutOff['chillyMin'] && score <= tierCutOff['frozenMin']
      ? 'bg_chilly'
      : score > tierCutOff['frozenMin'] && score <= tierCutOff['subzeroMin']
      ? 'bg_frozen'
      : score > tierCutOff['subzeroMin']
      ? 'bg_subzero'
      : '';
}

export function pieChartBgColor(score) {
  let tierCutOff = JSON.parse(localStorage.getItem('tier_cutoff'));
  if (!score) return 'default_bg';
  if (score && tierCutOff)
    return score > -9999 && score <= tierCutOff['lukeWarmMin']
      ? '#EA580B'
      : score > tierCutOff['lukeWarmMin'] && score <= tierCutOff['chillyMin']
      ? '#F59E0B'
      : score > tierCutOff['chillyMin'] && score <= tierCutOff['frozenMin']
      ? '#2BD4BE'
      : score > tierCutOff['frozenMin'] && score <= tierCutOff['subzeroMin']
      ? '#A9B9F1'
      : score > tierCutOff['subzeroMin']
      ? '#FFFFFF'
      : '';
}
export function fetchTierCutOff(score) {
  let tierCutOff = JSON.parse(localStorage.getItem('tier_cutoff'));
  let tier = setTier(score)?.toLowerCase() + 'Max';
  let tierName = tierCutOff[tier] == '99999999' ? '' : tierCutOff[tier];
  return tierName;
}

export function fetchGrowthSign(score) {
  return '';
  // return score > 0 ? '&uarr;' : '&darr;';
}

export function simpleNumberFormatter(number) {
  // 1234567 into 12,34,567
  if (number == 0) {
    return 0;
  } else if (!number) {
    return '';
  }
  number = Math.round(number);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function chillFactorCalculation(number, score, fromGraph = false) {
  if (!number || !score) return number + '' + score;
  let value = number * score;
  value = Math.round(value);
  return fromGraph
    ? value
    : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function sortData(data, metric) {
  return data.sort((value1, value2) => {
    // Handle cases where either "metric" value is null or undefined
    if (value1[metric] === null || value1[metric] === undefined) {
      return 1; // Place "a" after "b"
    } else if (value2[metric] === null || value2[metric] === undefined) {
      return -1; // Place "b" after "a"
    }
    // Sort in descending order based on the "metric" value
    return value2[metric] - value1[metric];
  });
}

export function setMinMaxTierCutOff(tierCutoffs) {
  if (!tierCutoffs) return;

  const { warm, lukewarm, chilly, frozen, subzero } = tierCutoffs;

  let tiersBreakpoint = {
    warmMin: warm,
    warmMax: lukewarm - 1,
    lukeWarmMin: lukewarm,
    lukeWarmMax: chilly - 1,
    chillyMin: chilly,
    chillyMax: frozen - 1,
    frozenMin: frozen,
    frozenMax: subzero - 1,
    subzeroMin: subzero,
    subzeroMax: 1300000,
  };
  return tiersBreakpoint;
}

export const chillFactorConfig = [
  {
    id: 'warm',
    title: 'Warm',
    header: 'You seem a little',
    range: '0-5,500',
    rangeStyle: 'background-color: rgba(255, 214, 0, 0.12)',
    rangeClass: 'text-[#ffa366]',
    imageUrl: WarmModelSnow,
    textUrl: WarmText,
    buttonBgClass: 'bg-[#ff6a001f] hover:bg-[#ff6a003d]',
  },
  {
    id: 'lukewarm',
    title: 'Luke Warm',
    header: 'You’ve Cooled off to',
    range: '5,500-60,000',
    rangeStyle: 'background-color: rgba(255, 214, 0, 0.12);',
    rangeClass: 'text-[#FFD600]',
    imageUrl: LukewarmModelSnow,
    textUrl: LukewarmText,
    buttonBgClass: 'bg-[#FFD6001F] hover:bg-[#FFD6003D]',
  },
  {
    id: 'chilly',
    title: 'Cold',
    header: 'Brrr it’s starting to get',
    range: '60,000-65,000',
    rangeStyle: 'background-color: rgba(28, 252, 226, 0.12);',
    rangeClass: 'text-[#1cfce2]',
    imageUrl: ChillyModelSnow,
    textUrl: ChillyText,
    buttonBgClass: 'bg-[#1CFCE21F] hover:bg-[#1CFCE23D]',
  },
  {
    id: 'frozen',
    title: 'Frozen',
    header: 'You Look a little',
    range: '65,000-70,000',
    rangeStyle: 'background-color: rgba(58, 104, 255, 0.24);',
    rangeClass: 'text-indigo-400',
    imageUrl: FrozenModelSnow,
    textUrl: FrozenText,
    buttonBgClass: 'bg-[#6BC1FF1F] hover:bg-[#6BC1FF3D]',
  },
  {
    id: 'subzero',
    title: 'Subzero',
    header: 'You are Absolute',
    range: '75,000',
    rangeStyle: 'background-color: rgba(243, 242, 246, 0.24)',
    rangeClass: 'text-[#BC8DEA]',
    imageUrl: SubzeroModelSnow,
    textUrl: SubzeroText,
    buttonBgClass: 'bg-[#CFC3FF1F] hover:bg-[#CFC3FF3D]',
  },
];

export const shareBorderColor = {
  warm: 'border-[#ffa366]',
  lukewarm: 'border-yellow-300',
  chilly: 'border-[#168D77]',
  frozen: 'border-indigo-400',
  subzero: 'border-[#BC8DEA]',
};

export const modalBorderColor = {
  warm: 'border-[#ff8545]',
  lukewarm: 'border-[#e9c400]',
  chilly: 'border-[#04e0c7]',
  frozen: 'border-[#45a8ff]',
  subzero: 'border-[#7d0ce0]',
};

export const snowClass = {
  warm: 'w-1/4',
  lukewarm: 'w-2/5',
  chilly: 'w-3/5',
  frozen: 'w-4/5',
  subzero: '',
};

export function calculateCurrentSnapShot(checkpointStatsHistorical) {
  let formatterSnapshot = {
    current_snapshot: checkpointStatsHistorical?.current_snapshot,
    most_recent_components: checkpointStatsHistorical?.most_recent_components,
    most_recent_metrics: checkpointStatsHistorical?.most_recent_metrics,
    leaderboard_count:
      checkpointStatsHistorical?.total_numbers_from_leaderboard,
    components_historical: checkpointStatsHistorical?.components_historical,
  };
  if (checkpointStatsHistorical?.current_snapshot?.tier_cutoffs) {
    localStorage.setItem(
      'tier_cutoff',
      JSON.stringify(
        setMinMaxTierCutOff(
          checkpointStatsHistorical?.current_snapshot.tier_cutoffs,
        ),
      ),
    );
  }
  calculateRange();
  return formatterSnapshot;
}

export function calculateRange(
  selectedConfig,
  chillFactorConfig,
  checkpointStatsHistorical,
) {
  if (selectedConfig) {
    if (selectedConfig.id == 'warm') {
      chillFactorConfig.find(
        (x) => x.id == 'warm',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs['warm'],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'lukewarm'
        ],
      )}`;
    } else if (selectedConfig.id == 'lukewarm') {
      chillFactorConfig.find(
        (x) => x.id == 'lukewarm',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'lukewarm'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'chilly'
        ],
      )}`;
    } else if (selectedConfig.id == 'chilly') {
      chillFactorConfig.find(
        (x) => x.id == 'chilly',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'chilly'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'frozen'
        ],
      )}`;
    } else if (selectedConfig.id == 'frozen') {
      chillFactorConfig.find(
        (x) => x.id == 'frozen',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'frozen'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'subzero'
        ],
      )}`;
    } else if (selectedConfig.id == 'subzero') {
      chillFactorConfig.find(
        (x) => x.id == 'subzero',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'subzero'
        ],
      )}`;
    }
  }
}
export function urlInspection(str) {
  const urlPattern = /^(https?:\/\/)?([\w\-]+\.)+[a-z]{2,6}(\/\S*)?$/i;
  return str.test(inputValue.value);
}

export function getGameIcon(name, projectImagesBaseLink) {
  if (!name) {
    return '';
  }
  name = name.replace(/\s/g, '');
  name = name === 'dragoncrypto' ? 'dragoncryptogame' : name;
  let extension = [
    'castlecrush',
    'dragoncryptogame',
    'paradisetycoon',
  ].includes(name)
    ? '.png'
    : '.svg';
  return projectImagesBaseLink + name + extension;
}

import WarmModelSnow from '@/images/warm_modal_snow.svg';
import LukewarmModelSnow from '@/images/lukewarm_modal_snow.svg';
import ChillyModelSnow from '@/images/chilly_modal_snow.svg';
import FrozenModelSnow from '@/images/frozen_modal_snow.svg';
import SubzeroModelSnow from '@/images/subzero_modal_snow.svg';
import WarmText from '@/images/warm_text.svg';
import LukewarmText from '@/images/lukewarm_text.svg';
import ChillyText from '@/images/chilly_text.svg';
import FrozenText from '@/images/frozen_text.svg';
import SubzeroText from '@/images/subzero_text.svg';

export default {
  colors,
  coinBaseColors,
  getRandomId,
  percentage,
  formatNumber,
  localDateEqualToUtc,
  truncate,
  toDateTime,
  decimals,
  roundSmallPercent,
  rounded,
  numericalDate,
  readableDate,
  readableDateTime,
  timeWithinToday,
  minimalTimeWithinToday,
  relativeTime,
  formatPrice,
  slugify,
  roundedBtc,
  titleize,
  upperCase,
  capitalizeWords,
  formatPricing,
  formatPercent,
  formatBigPricing,
  humanReadableToNumber,
  filterToDateTime,
  isValidUrl,
  timeToUnix,
  calculateChange,
  convertUnderScoreToDash,
  filtersArray,
  findMyStats,
  setScoreBaseColor,
  setTier,
  fetchGrowthSign,
  fetchTierCutOff,
  simpleNumberFormatter,
  chillFactorCalculation,
  sortData,
  setMinMaxTierCutOff,
  snowClass,
  modalBorderColor,
  shareBorderColor,
  chillFactorConfig,
  calculateCurrentSnapShot,
  calculateRange,
  getGameIcon,
  terminalFormatDecimals,
};
