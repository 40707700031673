import axios from 'axios';
export class ProjectApi {
  async reqMethod(path, query) {
    try {
      return [(await axios.get(path, { params: query })).data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }

  async fetchActivityGrowthChartData(urlParam, query) {
    const [res, error] = await this.reqMethod(
      `/projects/${urlParam}/chart_data`,
      query,
    );

    return error ? error : res;
  }

  async fetchTokens(urlParam) {
    if (!urlParam) {
      return null;
    }

    const [res, error] = await this.reqMethod(
      `/projects/${urlParam}/universe_data`,
    );
    return error ? error : (res || null);
  }

  async fetchNews(urlParam) {
    const [res, error] = await this.reqMethod(`/projects/${urlParam}/news`);

    return error ? error : res;
  }
}
