<template>
  <div v-if="loading" class="flex h-screen w-full items-center justify-center">
    <IconLoading class="h-10 w-10" />
  </div>
  <div v-else-if="!address"
    class="flex h-screen w-full items-center justify-center font-light uppercase text-gray-400 text-xl">
    Connect Wallet Address to see Wrapped
  </div>
  <div v-else-if="!wrappedData || typeof wrappedData !== 'object'"
    class="flex h-screen w-full items-center justify-center">
    <NoDataFound />
  </div>
  <div v-else class="space-y-2 py-4">
    <div class="">
      <HeaderSection />
    </div>
    <div
      v-if="formattedWrapped && formattedWrapped.contracts"
      class="w-full grid-cols-3 items-center justify-center gap-5 space-y-5 lg:grid lg:space-y-0"
    >
      <TopContracts />
      <TopStats />
    </div>
    <div class="">
      <AccoladesStats />
    </div>
    <div class="">
      <ChillFactorRating />
    </div>
  </div>
</template>

<script setup>
import HeaderSection from './HeaderSection.vue';
import TopContracts from './TopContracts.vue';
import TopStats from './TopStats.vue';
import AccoladesStats from './AccoladesStats.vue';
import ChillFactorRating from './ChillFactorRating.vue';
import IconLoading from '../../icons/IconLoading.vue';
import { titleize, getGameIcon } from '../../composeables/filters';
import badgesConfiguration from './Badges';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import { WrappedApi } from '../Wrapped/WrappedApi';
const api = new WrappedApi();

import { onMounted, ref, inject, computed, provide, readonly, isProxy, toRaw } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const universeDefiTokens = inject('universeDefiTokens');
const universeTokens = inject('universeTokens');
const universeProjects = inject('universeProjects');
const universeNFTs = inject('universeNFTs');

const address = ref('');
const loading = ref(true);
const wrappedData = ref(null);

onMounted(() => {
  address.value = localStorage.getItem('my_address')
    ? JSON.parse(localStorage.getItem('my_address'))
    : '';
  Promise.all([fetchWrappedStats()]);
});

async function fetchWrappedStats() {
  loading.value = true;
  try {
    if (!address.value) {
      wrappedData.value = null;
      return;
    }
    const data = await new WrappedApi().fetchWrappedStats();
    wrappedData.value = typeof data === 'object' ? data : null;
  } catch (error) {
    console.error('Error fetching wrapped stats:', error);
    wrappedData.value = null;
  } finally {
    loading.value = false;
  }
}
const formattedWrapped = computed(() => {
  if (!wrappedData.value) {
    return {
      contracts: [],
      stats: [],
      badges: [],
    };
  }

  const { contracts = [], stats = [], badges = [] } = wrappedData.value;
  const result = {
    contracts: formatContracts(contracts),
    stats: formatStats(stats),
    badges: formatBadges(badges),
  };
  return result;
});
provide('wrapped-data', readonly(formattedWrapped));
provide('loading', readonly(loading));

// FORMATE CONTRACTS
function formatContracts(contracts) {
  return contracts
    .flatMap((item) => {
      const internalData = getInternalData(item);
      return {
        entity_name: titleize(item.entity_name, '_'),
        entity_vertical: item.entity_vertical,
        contract_address: item.contract_address,
        transaction_count: item.transaction_count,
        link: getLink(item, internalData),
        icon: internalData ? getIcon(internalData) : null,
      };
    })
    .filter(Boolean);
}

const projectImagesBaseLink = store.getters.projectImagesBaseLink;
function getInternalData(item) {
  let data = null;
  const identifier = item.mapping_id == null ? item.entity_name : item.mapping_id;

  switch (item.entity_vertical) {
    case 'defi':
      data = fetchEntityData(universeDefiTokens.value, identifier, 'ticker');
      break;
    case 'tokens':
    case 'projects':
      data = fetchEntityData(universeTokens.value, identifier, 'coin_uid');
      break;
    case 'gamefi':
      data = fetchEntityData(universeProjects.value, identifier, 'game');
      if (!data) return data = null
      let rawData = toRaw(data)
      rawData.icon = getGameIcon(rawData.game, projectImagesBaseLink);
      data = rawData
      break;
    case 'nft':
      data = fetchEntityData(universeNFTs.value, identifier, 'collection_name');
      break;
    default:
      data = null;
  }
  return data;
}

function getLink(item, internalData) {
  return `https://snowtrace.io/address/${item?.contract_address}`;
  // return internalData ? `/${item.entity_vertical}/${internalData?.ticker}` : `https://snowtrace.io/address/${item?.contract_address}`;
}

function getIcon(internalData) {
  return internalData?.icon;
}

function fetchEntityData(dataSource, identifier, mapping_entity) {
  if (!dataSource) return null;
  return dataSource.find(
    (item) =>
      item[mapping_entity].toLocaleLowerCase() == identifier?.toLocaleLowerCase(),
  );
}
// FORMATE STATS
function formatStats(stats) {
  return stats?.flatMap((item) => {
    const createStat = (title, value, percentile, tooltip) => ({
      title,
      value,
      diff_in_address: calculateTopPercentage(percentile),
      tooltip: tooltip,
    });

    return [
      createStat(
        'Total Transactions',
        item.txns_out,
        item.txns_percentile,
        'Number of transactions you sent this year',
      ),
      createStat(
        'Total Days Active',
        item.unique_days,
        item.days_percentile,
        'Number of days this year that you sent at least one transaction',
      ),
      createStat(
        'Unique NFTs',
        item.unique_nfts,
        item.nfts_percentile,
        'Number of unique NFT contracts you have traded this year',
      ),
      createStat(
        'Unique Tokens',
        item.unique_tokens,
        item.tokens_percentile,
        'Number of unique tokens you have traded this year',
      ),
      createStat(
        'Unique Contracts',
        item.unique_contracts,
        item.contracts_percentile,
        'Number of unique contracts you have interacted with this year',
      ),
    ];
  });
}

function calculateTopPercentage(value) {
  const percentage = 100 - value * 100;
  return percentage < 0.0099
    ? percentage.toFixed(4)
    : percentage < 1
    ? percentage.toFixed(2)
    : Math.round(percentage);
}

//FORMAT BADGES
function formatBadges(badges) {
  return badges?.map((b) => {
    const badge = badgesConfiguration[b.badge_name];
    return {
      title: badge?.title || '',
      icon: badge?.icon,
      description: badge?.description || '',
      tooltip: null,
      rewarded: b.rewarded === 'true',
    };
  });
}
</script>
