<template>
  <div class="py-4 font-semibold text-2xl md:py-8">Chill Factor Rating</div>
  <ChillfactorWidget
    :loading="loading"
    :current-snap-shot="currentSnapShot"
    :rank="rank"
    :checkpoint-stats-historical="checkpointStatsHistorical"
    :wrapped-css="wrappedCss"
    :image-loading="imageLoading"
    @shared="shareOnTwitter"
    :change-in-percentage="changeInPercentage"

    class="w-full"
  />
  <!-- Hidden for Sharing -->
  <div
    style="width: 700px; height: 350px"
    class="absolute top-0 left-0 z-[-9999] space-y-4 lg:flex lg:space-y-0 lg:space-x-4"
  >
    <div
      style="width: 700px; height: 350px"
      id="swapped-chill-factor-score-card"
      class="relative rounded-2xl"
      :class="currentSnapShot?.current_snapshot?.score ? '!bg-primary' : ''"
    >
      <BaseCard
        style="width: 700px; height: 350px"
        :class="[
          checkpointStatsHistorical?.current_snapshot?.score
            ? `${setBgScoreBaseColor(
                checkpointStatsHistorical?.current_snapshot?.score,
              )}`
            : 'default_mcf_bg',
          `${checkpointStatsHistorical?.current_snapshot?.tier?.toLowerCase()}-border`,
        ]"
      >
        <div class="h-full w-full">
          <div
            v-if="selectedConfig.id != 'warm'"
            class="exclude absolute -left-2.5 -right-2.5 z-20 block"
            :class="[
              snowClass?.[selectedConfig.id],
              ['chilly', 'lukewarm'].includes(selectedConfig?.id)
                ? '-top-2.5'
                : '-top-5',
            ]"
          >
            <img :src="selectedConfig.imageUrl" class="w-full flex-shrink-0" />
          </div>
          <div
            class="flex w-full flex-col items-center justify-center space-y-4 pt-8"
          >
            <div class="font-black text-lg tracking-widest">
              {{ selectedConfig?.header }}
            </div>
            <div>
              <img :src="selectedConfig.textUrl" class="flex-shrink-0" />
            </div>
            <div
              class="text[#f3f2f6b8] font-medium leading-4 text-xs tracking-wide"
            >
              Chill Factor Range
            </div>
          </div>
          <div class="flex items-center justify-center pt-2">
            <button
              class="flex h-7 cursor-default items-center space-x-1 rounded px-4 text-sm"
              :style="selectedConfig?.rangeStyle"
              :class="selectedConfig?.rangeClass"
            >
              <CrownIcon />
              <span>{{ selectedConfig?.range }}</span>
            </button>
          </div>
          <div class="flex items-center justify-center pt-2 font-medium"  v-if="changeInPercentage"> You have increased your Chill Factor by <span class=" px-1" :class="`${setTier(
              checkpointStatsHistorical?.current_snapshot?.score,
            )?.toLowerCase()}-text-gradient`">{{ changeInPercentage }}%</span> this year. </div>
          <div
            :class="`${setTier(
              checkpointStatsHistorical?.current_snapshot?.score,
            )?.toLowerCase()}-text-gradient py-6 text-center font-black text-4xl`"
          >
            {{
              simpleNumberFormatter(
                checkpointStatsHistorical?.current_snapshot?.score,
              )
            }}
          </div>
          <div
            :class="[
              !loadingImage ? 'flex' : 'flex',
              'included items-center justify-center space-x-3 divide-x',
            ]"
          >
            <div>
              <img
                class="h-2.5"
                src="../../images/frosty_logo.svg"
                alt="Frosty Metrics Logo"
              />
            </div>
            <div class="px-2">
              <img
                class="h-3"
                src="../../images/the_tie_logo.svg"
                alt="The Tie Logo"
              />
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import ChillfactorWidget from '../ChillFactor/ChillfactorWidget.vue';
import { ChillFactorApi } from '../ChillFactor/ChillFactorApi';
import BaseCard from '../../Shared/BaseCard.vue';
const api = new ChillFactorApi();
const myAddress = ref(
  localStorage.getItem('my_address')
    ? JSON.parse(localStorage.getItem('my_address'))
    : '',
);
// fetch Checkpoints and historical data
onMounted(() => {
  if (myAddress.value?.length > 0) {
    Promise.all([fetchAddress()]);
    const currentDate = new Date().toDateString();
    const showModalKey = `level_${myAddress.value}_${currentDate}`;
    if (localStorage.getItem('level_acheivement') != showModalKey) {
      // showLevelModal.value = true;
      localStorage.setItem('level_acheivement', showModalKey);
    }
  }
});

const loading = ref(true);
const checkpointStatsHistorical = ref([]);

async function fetchAddress() {
  loading.value = true;
  checkpointStatsHistorical.value = await api.fetchStatsHistoricalData();
}

// CHILL FACTOR
import {
  makePng,
  imageDataUrl,
  loadingImage,
  uploadedImageUrl,
} from '~/composeables/shareChillFactor';

import {
  findMyStats,
  setScoreBaseTextColor,
  simpleNumberFormatter,
  setBgScoreBaseColor,
  setMinMaxTierCutOff,
  setTier,
  snowClass,
  chillFactorConfig,
  calculateRange,
  calculateCurrentSnapShot,
} from '../../composeables/filters';
import CrownIcon from '../../icons/CrownIcon.vue';

const generatingLink = ref(false);
const generatingLinkFor = ref(null);
const shareableLink = ref(null);
const imageBlob = ref(null);
const imageLoading = ref(null);

// For Stats
const currentSnapShot = computed(() => {
  let formatterSnapshot = calculateCurrentSnapShot(
    checkpointStatsHistorical.value,
  );
  loading.value = false;
  return formatterSnapshot;
});

const rank = ref();

const selectedConfig = computed(() => {
  const tier = setTier(
    checkpointStatsHistorical.value?.current_snapshot?.score,
  );
  const chillConfig =
    chillFactorConfig.find((item) => item.id === tier?.toLowerCase()) ?? {};
  return chillConfig;
});
const shareOnTwitter = async () => {
  imageLoading.value = true;
  let linkFor = 'twitter';
  const elem = document.getElementById('swapped-chill-factor-score-card');
  let dimensions = { height: elem.clientHeight, width: elem.clientWidth };
  await makePng(
    'swapped-chill-factor-score-card',
    null,
    false,
    dimensions,
    'swapped-chill-factor-score-card',
  );
  generatingLink.value = true;
  generatingLinkFor.value = linkFor;
  const file = await makeImageFile(uploadedImageUrl.value);
  await uploadChillFactor(file, linkFor);
};
const makeImageFile = async (dataUrl) => {
  let fetchUrl = await fetch(dataUrl);
  imageBlob.value = await fetchUrl.blob();
  return new File([imageBlob.value], 'chillFactor', { type: 'image/png' });
};
const uploadChillFactor = async (file, linkFor) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const data = await api.uploadImage(formData);
    generatingLink.value = false;
    shareableLink.value = data.url;
    switch (linkFor) {
      case 'twitter':
        makeTwitterUrl();
        generatingLinkFor.value = null;
        break;
    }
  } catch (e) {
    console.error(e);
  }
};
const twitterDescription = computed(() => {
  return `I just checked my Avalanche Chill Factor score on FrostyMetrics and am ${checkpointStatsHistorical.value?.current_snapshot?.tier.toUpperCase()} with a score of ${
    checkpointStatsHistorical.value?.current_snapshot?.score
  }. Are you chiller than me?

To check your Chill Factor visit Frosty Metrics: https://frostymetrics.com/
`;
});
const makeTwitterUrl = () => {
  if (shareableLink.value.length) {
    const twitterPostIntentUrl = new URL('https://twitter.com/intent/tweet?');
    twitterPostIntentUrl.searchParams.append('url', shareableLink.value);
    twitterPostIntentUrl.searchParams.append('text', twitterDescription.value);
    imageLoading.value = false;
    window.open(
      twitterPostIntentUrl.href,
      'Share Chill Factor',
      'width=700,height=650',
    );
  }
};

// CALCULATE CHANGE IN PERCENTAGE LAST YEAR SAME MONTH VS CURRENT MONTH
const changeInPercentage = computed(() => {
  let firstMonthData = currentSnapShot.value?.components_historical?.sort((a, b) => a.cur_date - b.cur_date).at(-1)
  let currentMonthData = currentSnapShot.value?.current_snapshot
  let changeInPercentage = (
    currentMonthData?.score - firstMonthData?.score >= 0
      ? Math.round(
          ((currentMonthData?.score - firstMonthData?.score) /
            firstMonthData?.score) *
            100
        )
      : 0
  );

  localStorage.setItem('changeInScorePercentage', changeInPercentage)
  return changeInPercentage
})

const wrappedCss = ref({
  stats: 'lg:w-1/2',
  bars: '2xl:w-44 w-14',
});
</script>
