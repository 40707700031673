<template>
  <div
    class="col-span-2 h-full w-full md:flex-row md:justify-between md:space-y-5"
  >
    <div
      class="w-full items-stretch gap-5 space-y-5 sm:flex sm:space-y-0 sm:py-0 md:h-[47%]"
    >
      <div
        v-for="item in stats.slice(0, 2)"
        :key="item"
        class="h-full w-full items-center justify-center"
      >
        <StatsCard :item="item" />
      </div>
    </div>
    <div
      class="w-full items-stretch gap-5 space-y-5 py-5 sm:flex sm:space-y-0 sm:py-0 md:h-[47%]"
    >
      <div
        v-for="item in stats.slice(2, 5)"
        :key="item"
        class="flex h-full w-full"
      >
        <StatsCard :item="item" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, inject } from 'vue';
import StatsCard from './StatsCard.vue';

const wrappedData = inject('wrapped-data');

const requiredStats = [
  'Total Transactions',
  'Total Days Active',
  'Unique Contracts',
  'Unique NFTs',
  'Unique Tokens',
];
const stats = computed(() => {
  const filtered = wrappedData.value?.stats.filter((item) =>
    requiredStats.includes(item.title),
  );
  return filtered.map((x) => ({
    ...x,
    definition: x.tooltip,
    subtext:
      x?.diff_in_address == 100
        ? ''
        : `Top ${x?.diff_in_address}% of addresses`,
  }));
});
</script>
