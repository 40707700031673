import centurionBadge from '../../images/badges/centurion_badge.png';
import captainsSeal from '../../images/badges/captains_seal.png';
import pegsMavenBadge from '../../images/badges/pegs_maven_badge.png';
import dextrsBadgeOfApproval from '../../images/badges/dextrs_badge_of_approval.png';
import ronniesCompanion from '../../images/badges/ronnies_companion.png';
import benqiBadge from '../../images/badges/benqi_badge.png';
import gogoPooler from '../../images/badges/gogo_pooler.png';
import templeTrader from '../../images/badges/temple_trader.png';
import unicornFriend from '../../images/badges/unicorn_friend.png';
import xsteaker from '../../images/badges/xsteaker.png';
import web3Gamer from '../../images/badges/web3_gamer.png';
import defiNomad from '../../images/badges/defi_nomad.png';
import nftTrader from '../../images/badges/nft_trader.png';
import dailyChill1Month from '../../images/badges/daily_chill_1_month.png';
import dailyChill3Months from '../../images/badges/daily_chill_3_months.png';
import monthlyChill1Year from '../../images/badges/monthly_chill_1_year.png';
import BrokenImage from '../../images/BrokenImage.svg';

function createUrl(name, url) {
  return `<a style='color: #3c65ff' href='${url}'>${name}</a>`;
}
export const badgesConfiguration = {
  centurion_badge: {
    title: 'Centurion Badge',
    description: `You used ${createUrl('The Arena', 'https://arena.social/')}`,
    icon: centurionBadge,
  },
  captains_seal: {
    title: 'Captains Seal',
    description: `You used ${createUrl('Salvor', 'https://salvor.io/')}`,
    icon: captainsSeal,
  },
  pegs_maven_badge: {
    title: 'Pegs Maven Badge',
    description: `You used ${createUrl('Joepegs', 'https://joepegs.com/')}`,
    icon: pegsMavenBadge,
  },
  dextrs_badge_of_approval: {
    title: "Dextr's Badge of Approval",
    description: `You used ${createUrl('Dexalot', 'https://dexalot.com/')}`,
    icon: dextrsBadgeOfApproval,
  },
  ronnies_companion: {
    title: "Ronnie's Companion",
    description: `You used ${createUrl('Aave', 'https://aave.com/')}`,
    icon: ronniesCompanion,
  },
  benqi_badge: {
    title: 'BENQI Badge',
    description: `You used ${createUrl('BENQI', 'https://benqi.fi/')}`,
    icon: benqiBadge,
  },
  gogo_pooler: {
    title: 'GoGo Pooler',
    description: `You used ${createUrl(
      'Gogo Pool',
      'https://www.gogopool.com/',
    )}`,
    icon: gogoPooler,
  },
  temple_trader: {
    title: 'Temple Trader',
    description: `You used ${createUrl(
      'Pharaoh',
      'https://pharaoh.exchange/',
    )}`,
    icon: templeTrader,
  },
  unicorn_friend: {
    title: 'Unicorn Friend',
    description: `You used ${createUrl('Uniswap', 'https://app.uniswap.org/')}`,
    icon: unicornFriend,
  },
  xsteaker: {
    title: 'xSTEAKer',
    description: `You used ${createUrl(
      'SteakHut',
      'https://steakhut.finance/',
    )}`,
    icon: xsteaker,
  },
  web3_gamer: {
    title: 'Web3 Gamer',
    description: `You played a GameFi game`,
    icon: web3Gamer,
  },
  defi_nomad: {
    title: 'DeFi Nomad',
    description: `You used a DeFi project`,
    icon: defiNomad,
  },
  nft_trader: {
    title: 'NFT Trader',
    description: `You traded an NFT`,
    icon: nftTrader,
  },
  daily_chill_1_month: {
    title: 'Daily Chill (30 days)',
    description: 'You sent a transaction on at least 30 days this year',
    icon: dailyChill1Month,
  },
  daily_chill_3_months: {
    title: 'Daily Chill (90 days)',
    description: 'You sent a transaction on at least 90 days this year',
    icon: dailyChill3Months,
  },
  monthly_chill_1_year: {
    title: 'Monthly Chill (1 year)',
    description: 'You sent a transaction every month this year',
    icon: monthlyChill1Year,
  },
};

export default badgesConfiguration;
