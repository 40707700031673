<template>
  <BaseModal :show="show" @close="$emits('close')">
    <template #content>
      <div class="relative bg-blue-950">
        <div class="flex items-start justify-between">
          <div class="space-y-2 px-2">
            <img
              class="block h-6"
              src="../../images/frosty_logo.svg"
              alt="Frosty Metrics Logo"
            />
            <span
              class="wrapped-gradient block whitespace-nowrap bg-clip-text text-sm font-normal text-transparent"
            >
              Your Avalanche Wrapped is here
            </span>
          </div>
          <XMarkIcon class="h-5 w-5 cursor-pointer" @click="$emits('close')" />
        </div>
        <div class="mx-2 my-4 h-px bg-blue-500"></div>
        <template v-for="s in wrappedSteps" :key="s.id">
          <div
            v-if="step == s.id"
            class="h-[28rem] w-[550px] space-y-2 overflow-y-auto px-2"
          >
            <span class="block text-xl font-semibold">{{ s.heading }}</span>
            <component :is="s.content" />
          </div>
        </template>

        <!-- FOR IMAGE EXPORT -->
        <div class="absolute -bottom-[9999px] z-[-9999] flex justify-center">
          <div :id="id" class="my-4 w-[1100px] bg-blue-900 py-4">
            <div
              class="relative mx-auto w-[550px] justify-center rounded-md border border-blue-850 bg-blue-950 p-4"
            >
              <div class="absolute -left-2.5 -top-3.5 z-20">
                <img
                  src="../../images/footer_snow_left.svg"
                  class="flex-shrink-0"
                />
              </div>
              <div class="flex items-start justify-between py-4">
                <div class="space-y-2 px-2">
                  <img
                    class="block h-5"
                    src="../../images/frosty_logo.svg"
                    alt="Frosty Metrics Logo"
                  />
                  <span
                    class="wrapped-gradient block whitespace-nowrap bg-clip-text text-sm font-normal capitalize text-transparent"
                  >
                    Your Avalanche Wrapped is here
                  </span>
                </div>
              </div>
              <div class="mx-2 my-4 h-px bg-blue-500"></div>
              <template v-for="s in wrappedSteps" :key="s.id">
                <div
                  v-if="step == s.id"
                  class="h-[28rem] space-y-2 overflow-y-auto px-2"
                >
                  <span class="block text-xl font-semibold">
                    {{ s.heading }}
                  </span>
                  <component :is="s.content" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-between py-1">
        <div class="">
          <div v-if="step != 1" class="main-btn-gradient rounded p-px">
            <Button
              css-classes="px-3 py-2 rounded text-blue-300 bg-blue-900"
              @click="step = step - 1"
            >
              <ArrowLeftIcon class="h-4 w-4" />
            </Button>
          </div>
        </div>
        <div class="flex space-x-2">
          <div class="main-btn-gradient rounded p-px">
            <Button
              css-classes="px-4 py-2 rounded bg-blue-900"
              @click="shareCurrentStep"
            >
              <div class="flex space-x-2 text-blue-300">
                <template v-if="!preparingImageForShare">
                  <span class="block text-xs uppercase">Share</span>
                  <ShareIcon class="h-4 w-4" />
                </template>
                <template v-else>
                  <IconLoading class="h-4 w-4" />
                </template>
              </div>
            </Button>
          </div>
          <Button
            v-if="step < wrappedSteps.length"
            css-classes="main-btn-gradient px-4 py-2 rounded"
            @click="step = step + 1"
          >
            <div class="flex items-center space-x-2 text-black">
              <span class="block text-xs uppercase">Next</span>
              <ArrowRightIcon class="h-4 w-4" />
            </div>
          </Button>
          <Button
            v-if="step == wrappedSteps.length"
            css-classes="main-btn-gradient px-4 py-2 rounded"
          >
            <a
              href="/chill_factor"
              target="_blank"
              class="flex items-center space-x-2 text-black"
            >
              <span class="block text-xs uppercase">Visit Page</span>
              <ArrowRightIcon class="h-4 w-4" />
            </a>
          </Button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>
<script setup>
import { ref, computed, inject, onMounted } from 'vue';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ShareIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import BaseModal from '../BaseModal.vue';
import Button from '../Button.vue';
import WrappedModalStep1 from './modal/WrappedModalStep1.vue';
import WrappedModalStep2 from './modal/WrappedModalStep2.vue';
import WrappedModalStep3 from './modal/WrappedModalStep3.vue';
import WrappedModalStep4 from './modal/WrappedModalStep4.vue';
import { uploadedImageUrl, makePng } from '../../composeables/shareChillFactor';
import { ChillFactorApi } from '../../Pages/ChillFactor/ChillFactorApi';
import IconLoading from '../../icons/IconLoading.vue';

const cfApi = new ChillFactorApi();
const wrappedData = inject('wrapped-data');

defineProps({
  show: { type: Boolean, default: false },
});
const $emits = defineEmits(['close']);

const changeInScore = ref('');
onMounted(() => {
  changeInScore.value = localStorage.getItem('changeInScorePercentage') || '';
});

const step = ref(1);
const wrappedSteps = computed(() => {
  const contractsCount = wrappedData.value?.contracts?.length || 0;
  const achievedBadges =
    wrappedData.value?.badges?.filter((x) => x.rewarded).length || 0;
  const totalBadges = wrappedData.value?.badges?.length;
  const factor = localStorage.getItem('changeInScorePercentage') || '0';
  return [
    {
      id: 1,
      heading: `Your Top ${contractsCount} contracts`,
      content: WrappedModalStep1,
    },
    {
      id: 2,
      heading: `You were active in 2024!`,
      content: WrappedModalStep2,
    },
    {
      id: 3,
      heading: `You earned ${achievedBadges} out of ${totalBadges} badges!`,
      content: WrappedModalStep3,
    },
    {
      id: 4,
      heading: `Your Chill Factor went up ${factor}% this year!`,
      content: WrappedModalStep4,
    },
  ];
});

const id = 'avalanche-wrapped';
const preparingImageForShare = ref(false);
async function shareCurrentStep() {
  try {
    preparingImageForShare.value = true;
    const elem = document.getElementById(id);
    const dimensions = { height: elem.clientHeight, width: elem.clientWidth };
    await makePng(id, null, false, dimensions);
    const file = await fetchImage(uploadedImageUrl.value);
    await uploadImage(file);
  } catch (err) {
    console.log('--------- uploadImage shareCurrentStep ---------');
    console.log(e);
  }
}
const fetchImage = async (dataUrl) => {
  const fetchUrl = await fetch(dataUrl);
  const blob = await fetchUrl.blob();
  return new File([blob], 'wrapped', { type: 'image/png' });
};
const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const data = await cfApi.uploadImage(formData);
    preparingImageForShare.value = false;
    shareOnTwitter(data.url);
  } catch (e) {
    preparingImageForShare.value = false;
    console.log('--------- uploadImage ERROR ---------');
    console.log(e);
  }
};
const shareOnTwitter = (link) => {
  if (link) {
    const base = window.location.origin;
    const twitterIntendURL = new URL('https://twitter.com/intent/tweet?');
    twitterIntendURL.searchParams.append('url', link);
    twitterIntendURL.searchParams.append(
      'text',
      `🔺My 2024 #AvalancheWrapped is here! 🎉\n\n#AvalancheWrapped showed me my activity metrics, badges, and Chill Factor growth on the Avalanche blockchain this year!\n\nCheck yours out @frostymetrics ${base}/wrapped`,
    );
    window.open(
      twitterIntendURL.href,
      'Avalanche Wrapped!',
      'width=700,height=650',
    );
  }
};
</script>
