<template>
  <div>
    <BaseCard
      class="flex flex-shrink flex-col"
      :classes="{ headerPadding: 'py-1' }"
    >
      <template #header>
        <div class="w-full space-y-1">
          <div class="text-base flex items-center">
            Recommendations for you
            <Tooltip
              text="Based on your activity, you may be interested in these Projects"
              class="ml-1"
            >
              <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
            </Tooltip>
          </div>
          <div class="flex h-80 space-x-3 overflow-x-auto py-1">
            <div
              v-if="topRecommendations?.length > 0"
              class="flex h-full space-x-3"
            >
              <template
                v-for="(item, index) in topRecommendations"
                :key="index"
              >
                <RecommendedBaseCard
                  :metrics="[
                    { Price: 'price', prefix: '$', numberFormatter: true },
                    { 'Market Cap': 'market_cap', prefix: '$' },
                    { 'Transactions': 'num_transactions_1d' },
                    { TVL: 'tvl', prefix: '$' },
                  ]"
                  :data="item"
                  class="min-w-[15rem] flex-1"
                />
              </template>
            </div>
            <div v-else class="flex h-full w-full items-center justify-center">
              <NoWalletConnected
                class="lol grid h-full w-full grid-cols-1"
                text="Connect Wallet Address to view Recommendations"
              />
            </div>
          </div>
        </div>
      </template>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { RecommendationApi } from '../Recommendations/RecommendationApi';
import BaseCard from '../../Shared/BaseCard.vue';
import RecommendedBaseCard from '../../Shared/BaseCardRecommended.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import NoWalletConnected from '../../load_templates/NoWalletConnected.vue';

const api = new RecommendationApi();
const topRecommendations = ref([]);
onMounted(async () => {
  await fetchRecommendations();
});
async function fetchRecommendations() {
  topRecommendations.value = await api.fetchTopRecommendations();
  topRecommendations.value = topRecommendations.value?.sort((a, b) =>
    b.data ? 1 : -1,
  );
}
</script>
