<template>
  <BaseCard class="flex h-[500px] flex-shrink flex-col">
    <template #header>
      <div class="flex space-x-2">
        <div>L1 Comparison</div>
        <Tooltip
          text="A comparison of key metrics for all L1s based on the most recent day of data."
          :id="1"
        >
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </div>
    </template>
    <div class="flex h-[28rem] flex-shrink-0 flex-grow flex-col rounded">
      <div
        class="wrap flex-1 overflow-hidden overflow-x-auto overflow-y-auto rounded-lg shadow text-sm"
      >
        <div v-if="loading" class="flex h-full items-center justify-center">
          <IconLoading class="m-auto h-8 w-8" />
        </div>
        <div v-else-if="tableData?.length > 0" class="w-full">
          <Table :config="tableConfig" :data="tableData"></Table>
        </div>
        <span
          v-else-if="!loading"
          class="mt-auto flex h-full w-full justify-center"
        >
          <NoDataFound />
        </span>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import NoDataFound from '../../../load_templates/NoDataFound.vue';
import Table from '../../../Shared/Table.vue';
import { SubnetApi } from '../SubnetApi.js';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../../Shared/Tooltip.vue';
import IconLoading from '../../../icons/IconLoading.vue';
import BaseCard from '../../../Shared/BaseCard.vue';
import { sortData } from '../../../composeables/filters';
const $subnetApi = new SubnetApi();
const loading = ref(false);
const tableData = ref([]);

// Transactions
async function getSubnetComparison() {
  loading.value = true;
  tableData.value = await $subnetApi.fetchSubnetComparison();
  await sumUpMetrics();
  tableData.value = sortData(tableData.value, 'active_addresses');
  loading.value = false;
}

onMounted(() => {
  getSubnetComparison();
});

const tableConfig = ref({
  cols: [
    { type: 'subnets', name: 'L1', id: 'subnets', textLeft: 'left' },
    // { type: 'text', name: 'ChainId', id: 'chain_id', textLeft: 'left' },
    { type: 'number', name: 'Active Addresses', id: 'active_addresses' },
    { type: 'number', name: 'Active Senders', id: 'active_senders' },
    { type: 'number', name: 'Gas Per Second (Avg)', id: 'gas_per_second_avg' },
    { type: 'number', name: 'Contracts', id: 'contracts' },
    { type: 'number', name: 'Contracts Deployers', id: 'contract_deployers' },
    { type: 'number', name: 'Total Addresses', id: 'total_addresses' },
    { type: 'number', name: 'Gas Used', id: 'gas_used' },
    { type: 'number', name: 'Gas Per Second (Max)', id: 'gas_per_second' },
    { type: 'float_number', name: 'TPS Avg', id: 'tps_avg' },
    { type: 'number', name: 'TPS Max', id: 'tps_max' },
    { type: 'number', name: 'Transaction Count', id: 'transaction_count' },
  ],
  paddingLeftRight: 'px-5',
});

function sumUpMetrics() {
  const testnetData = tableData?.value.filter(
    (item) => item.is_testnet === true,
  );
  const l1 = tableData.value.find((item) => item.chain_id === 'mainnet');
  // Merging the metrics
  const mergedData = testnetData.reduce((acc, obj) => {
    acc.active_addresses += obj.active_addresses;
    acc.active_senders += obj.active_senders;
    acc.gas_per_second_avg += obj.gas_per_second_avg;
    acc.contracts += obj.contracts;
    acc.contract_deployers += obj.contract_deployers;
    acc.total_addresses += obj.total_addresses;
    acc.gas_used += obj.gas_used;
    acc.gas_per_second += obj.gas_per_second;
    acc.tps_avg += obj.tps_avg;
    acc.tps_max = Math.max(acc.tps_max, obj.tps_max);
    acc.transaction_count += obj.transaction_count;
    return acc;
  });

  const metrics = [
    'active_addresses',
    'active_senders',
    'contract_deployers',
    'contracts',
    'gas_per_second',
    'gas_per_second_avg',
    'gas_used',
    'total_addresses',
    'tps_avg',
    'tps_max',
    'transaction_count',
  ];

  metrics.forEach((metric) => {
    l1[metric] = (mergedData[metric] || 0) + (l1[metric] || 0);
  });

  const index = tableData.value.findIndex(
    (obj) => obj.chain_id === l1.chain_id,
  );
  if (index !== -1) {
    tableData.value[index] = l1;
  }
}
</script>
