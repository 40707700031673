<template>
  <div class="space-y-4 pt-2">
    <BaseCard v-for="(s, i) in wrappedData.stats" :key="i" class="p-2">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-2 text-neutral-400">
          <span class="block text-sm">{{ s.title }}</span>
          <Tooltip :text="s.tooltip">
            <InformationCircleIcon class="h-4 w-4" />
          </Tooltip>
        </div>
        <span class="block font-semibold text-white text-xl">
          {{ rounded(s.value, 0) }}
        </span>
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { inject } from 'vue';
import BaseCard from '../../BaseCard.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import { rounded } from '../../../composeables/filters';
import Tooltip from '../../Tooltip.vue';

const wrappedData = inject('wrapped-data');
</script>
