<template>
  <div class="grid grid-cols-3 gap-2 text-center">
    <div
      v-for="(b, i) in achieved"
      :key="i"
      class="items-center justify-center rounded-lg border border-green-400 bg-[#091D20] !p-3"
    >
      <div class="flex flex-col items-center space-y-2 p-1">
        <div class="h-6 w-6 flex-shrink-0">
          <img :src="b.icon" class="h-full w-full object-contain" />
        </div>
        <div class="w-full">
          <div class="flex w-full items-center justify-center">
            <span class="block font-medium text-white text-xs">
              {{ b.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(b, i) in pending"
      :key="i"
      class="flex items-center justify-center rounded-lg border border-dashed border-blue-850"
    >
      <div class="flex flex-col items-center space-y-2 px-2 py-4 opacity-60">
        <div class="h-6 w-6 flex-shrink-0">
          <img :src="b.icon" class="h-full w-full object-contain" />
        </div>
        <span class="block font-semibold text-xs">
          {{ b.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';

const wrappedData = inject('wrapped-data');

const achieved = computed(() => {
  return wrappedData.value.badges.filter((x) => x.rewarded);
});
const pending = computed(() => {
  return wrappedData.value.badges.filter((x) => !x.rewarded);
});
</script>
