<template>
  <div class="mt-10 flex flex-col items-center">
    <h1 class="font-semibold text-3xl">
      Sign up for a free Frosty Metrics API key
    </h1>
    <a href="https://docs.frostymetrics.com" target="blank" class="mt-4"
      ><Button>View Documentation</Button></a
    >
  </div>
  <form
    @submit.prevent="handleSubmit"
    class="mx-auto mt-10 flex max-w-xs flex-col space-y-6"
  >
    <div>
      <label for="name" class="text-sm/6 block font-medium text-gray-400"
        >Name</label
      >
      <div class="mt-2">
        <input
          type="text"
          name="name"
          id="name"
          v-model="formData.name"
          class="sm:text-sm/6 block w-full rounded-md bg-gray-800 px-3 py-1.5 text-white text-base placeholder:text-gray-400 focus:outline focus:outline-0"
          placeholder="Enter full name"
        />
      </div>
    </div>
    <div>
      <label for="email" class="text-sm/6 block font-medium text-gray-400"
        >Email</label
      >
      <div class="mt-2">
        <input
          type="email"
          name="email"
          id="email"
          v-model="formData.email"
          class="sm:text-sm/6 block w-full rounded-md bg-gray-800 px-3 py-1.5 text-white text-base placeholder:text-gray-400 focus:outline focus:outline-0"
          placeholder="Enter email address"
        />
      </div>
    </div>
    <div>
      <label for="company" class="text-sm/6 block font-medium text-gray-400"
        >Company Name (optional)</label
      >
      <div class="mt-2">
        <input
          type="text"
          name="company"
          id="company"
          v-model="formData.company"
          class="sm:text-sm/6 block w-full rounded-md bg-gray-800 px-3 py-1.5 text-white text-base placeholder:text-gray-400 focus:outline focus:outline-0"
          placeholder="Enter company name"
        />
      </div>
    </div>
    <Modal
      :show-modal="showModal"
      modal-type="terms_of_use_api"
      @close-modal="showModal = false"
    >
      <template #header>
        <div class="mb-4 flex items-center justify-between">
          <h2 class="font-semibold text-lg">Terms of Service</h2>
          <button
            @click="showModal = false"
            class="text-gray-400 hover:text-gray-600"
          >
            <XMarkIcon class="h-5 w-5" />
          </button>
        </div>
      </template>
      <template #default>
        <div
          id="terms"
          class="terms max-h-96 overflow-y-scroll"
          @scroll="checkScroll"
        ></div>
      </template>
    </Modal>
    <div class="mt-4 flex space-x-2">
      <label
        for="acceptTerms"
        title="Click on the Terms to be able to accept them"
      >
        <input
          type="checkbox"
          id="acceptTerms"
          v-model="formData.acceptTerms"
          :disabled="!canAccept"
          :class="{ 'cursor-not-allowed opacity-50': !canAccept }"
        />
        I accept the
        <button
          type="button"
          class="underline"
          @click="
            showModal = true;
            canAccept = true;
          "
        >
          Terms of Service
        </button></label
      >
    </div>
    <button
      type="submit"
      :disabled="!formData.acceptTerms || isSubmitting"
      :title="
        formData.acceptTerms ? '' : 'Please accept the Terms before submitting'
      "
      class="rounded bg-gradient-to-br from-[#31E0FF] to-[#45A8FF] px-3 text-black sm:px-6 sm:py-2"
      :class="formData.acceptTerms ? '' : 'cursor-not-allowed opacity-50'"
      tooltip="Click on the Terms of Use first to accept them"
    >
      {{ isSubmitting ? 'Submitting...' : 'Email My API Key' }}
    </button>
    <div v-if="isSuccess" class="text-center text-green-500 text-xs">
      Please check your inbox for your API key!
    </div>
  </form>
</template>

<script setup>
import Button from '@/Shared/Button.vue';
import Modal from '@/Shared/Modal.vue';
import axios from 'axios';
import { ref } from 'vue';

const formData = ref({
  name: '',
  email: '',
  company: '',
  acceptTerms: false,
});

const canAccept = ref(false);
const showModal = ref(false);
const isSubmitting = ref(false);
const isSuccess = ref(false);

const handleSubmit = () => {
  isSubmitting.value = true;
  axios
    .post('/api_key_requests', formData.value)
    .then((response) => {
      formData.value = {
        name: '',
        email: '',
        company: '',
        acceptTerms: false,
      };
      isSuccess.value = true;
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};
</script>
